import React, { useState } from "react";
import MeaTag from "react-meta-tags";
import { BreadcrumbItem, Container, Label, Row } from "reactstrap";
import GroupLabelCom from "../Com/GroupLabelCom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link, useHistory } from "react-router-dom";
import "../scss/AddCrowd.scss";
import TargetPopulationCom from "../PrecisionMarketing/MarketingPlan/LaunchPlan/TargetPopulationCom";

const AddCrowd = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  
  const onHandleCallBack = () => {
    history.push("/customClusteringList");
  };
  const handleCancel = ()=>{
    
  };
  const handleSave = ()=>{};
  const saveActiveTagList = () => {};
  return (
    <React.Fragment>
      <div className="page-content target-population-Modal">
        <MeaTag>
          <title>新建人群</title>
        </MeaTag>
        <Container fluid className="addCrowd-container modal-body">
          <div className="page-title-left">
            <Row className="row-box">
              <div className="col-5">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="/customClusteringList">自定义分群</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="/addCrowd">新建人群</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
              <div className="col-7" style={{textAlign:'right',paddingRight:'50px',marginTop:'10px'}}>
                <button style={{marginRight:'15px'}}
                  type="button"
                  className="btn btn-outline-light waves-effect btn-cancel"
                  onClick={onHandleCallBack}
                >
                  取消
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect btn-save"
                  onClick={onHandleCallBack}
                >
                  保存
                </button>
              </div>
            </Row>
          </div>
          <div className="page-container" style={{ padding: "0 30px" }}>
            <AvForm
              className="needs-validation"
              style={{
                position: "relative",
                top: " 20px",
                padding: "0px 20px",
              }}
            >
              <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom01">
                    {" "}
                    <span style={{ color: "#e5001d" }}>* </span>人群名称：
                  </Label>
                </div>
                <div className="col-lg-11">
                  <AvField
                    name="firstname"
                    placeholder="如高价值人群，人群名称最多15个字"
                    type="text"
                    // errorMessage="Enter First Name"
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="validationCustom01"
                    style={{
                      width: "320px",
                      position: "relative",
                      top: "-5px",
                      left:'-10px',
                      height:'30px',
                    }}
                  />
                </div>
              </Row>
              <Label htmlFor="validationCustom01" style={{margin:'15px 0'}}>
                {" "}
                <span style={{ color: "#e5001d" }}>* </span>筛选条件：
              </Label>
            </AvForm>
            {/* <GroupLabelCom></GroupLabelCom> */}
            <TargetPopulationCom
              saveActiveTagList={saveActiveTagList}
            ></TargetPopulationCom>
          </div>
          <div className="page-footer"></div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default AddCrowd;

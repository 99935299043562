import React from "react";
import MeaTag from "react-meta-tags";
import {
  BreadcrumbItem,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../scss/AddDirectionalIssuanceSecurities.scss";
import { Link, useHistory } from "react-router-dom";

const AddDirectionalIssuanceSecurities = () => {
  const history = useHistory();
  const onHandleCallBack = () => {
    history.push("/customClusteringList");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MeaTag>
          <title>新建定向发券</title>
        </MeaTag>
        <Container fluid className="addDirectionalIssuanceSecurities-container">
          <div className="page-title-left">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/customClusteringList">自定义分群</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="/addDirectionalIssuanceSecurities">新建定向发券</Link>
              </BreadcrumbItem>
            </ol>
          </div>
          <div className="page-Container">
            <AvForm className="needs-validation">
              <Row className="row-box">
                <div className="col-lg-1" >
                  <Label
                    htmlFor="validationCustom01"
                    style={{ position: "relative", top: "10px" }}
                  >
                    <span style={{ color: "#e5001d" }}>* </span>活动名称：
                  </Label>
                </div>
                <div className="col-lg-11">
                  <AvField
                    name="firstname"
                    placeholder="最多15个汉字"
                    type="text"
                    errorMessage="请输入活动名称"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    style={{ width: "320px" }}
                  />
                </div>
              </Row>
              <Row  className="row-box" >
                <div className="col-lg-1"style={{marginTop:'15px'}}>
                  <Label htmlFor="validationCustom01">  <span style={{ color: "#e5001d" }}>* </span>推送人群：</Label>
                </div>
                <div className="col-lg-3" style={{top:'-10px',marginTop:'15px'}}>
                  <select
                    className="form-control"
                    name="choices-single-no-search"
                    id="choices-single-no-search"
                  >
                    <option value="0">青少年</option>
                    <option value="0">女性用户</option>
                    <option value="0">老用户</option>
                    <option value="0">高消费用户</option>
                  </select>
                </div>
              </Row>
              <Row className="row-box">
                <div className="col-lg-1" style={{ marginTop: "10px" }}>
                  <Label htmlFor="validationCustom02">
                    <span style={{ color: "#e5001d" }}>* </span>发放时间：
                  </Label>
                </div>
                <div className="col-lg-11" style={{ marginTop: "10px" }}>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="executionTime"
                      id="formRadios1"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="formRadios1">
                      立即发放
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="executionTime"
                      id="formRadios2"
                    />
                    <label htmlFor="formRadios2">定时发放</label>
                    <div className="div-trigger-date">
                      <Input
                        className="form-control"
                        type="date"
                        defaultValue="2019-08-19"
                        id="example-date-input"
                      />
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom03">
                    <span style={{ color: "#e5001d" }}>* </span>发放对象：
                  </Label>
                </div>
                <div className="col-lg-11">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IssuedTo"
                      id="formRadios4"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="formRadios4">
                      选择客户
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IssuedTo"
                      id="formRadios5"
                    />
                    <label htmlFor="formRadios5">全部客户</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="IssuedTo"
                      id="formRadios6"
                    />
                    <label htmlFor="formRadios6">指定人群</label>
                  </div>
                  <div className="div-select-people">
                    <span style={{ color: "#8558FA" }}>选择人群</span>
                    <span style={{ color: "#BDBDBD", marginLeft: "20px" }}>
                      若所选多个人群存在重复人员时，将不会对该人员重复发放
                    </span>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>标题</th>
                            <th>人群数量</th>
                            <th>操作</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>青少年</td>
                            <td>0</td>
                            <td>
                              <span style={{ color: "#8558FA" }}>删除</span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div style={{ color: "#e5001d" }}>
                      该群没有客户，请选择其他人群
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom03">
                    <span style={{ color: "#e5001d" }}>* </span>赠送优惠券：
                  </Label>
                </div>
                <div className="col-lg-11 col-size">
                  <span style={{ color: "#8558FA" }}>添加优惠券</span>
                  <span style={{ color: "#BDBDBD", marginLeft: "20px" }}>
                    最多支持添加15张优惠券
                  </span>
                  <div
                    className="table-responsive"
                    style={{ width: "90%", border: "1px solid #ccc" }}
                  >
                    <Table className="table mb-0" style={{}}>
                      <thead className="table-light">
                        <tr>
                          <th>名称</th>
                          <th>价值</th>
                          <th>裂变数量</th>
                          <th>限领次数</th>
                          <th>可用门店</th>
                          <th>发放数量</th>
                          <th>剩余/总量</th>
                        </tr>
                      </thead>{" "}
                      <tbody>
                        <tr></tr>
                      </tbody>
                      <div className="div-null">请选择优惠券</div>
                    </Table>
                  </div>
                </div>
              </Row>
              <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom03">通知方式：</Label>
                </div>
                <div className="col-lg-11 col-size">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="formrow-customCheck"
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="formrow-customCheck"
                    style={{ marginLeft: "10px" }}
                  >
                    短信
                  </Label>
                </div>
                <div className="div-sms-template">
                  【有赞】 “店铺名称” 为您送上了 “优惠券数量”
                  张优惠券，已悄悄放入您的账户中，赶紧打开看看吧
                  “http://XXX.XXX”
                </div>
              </Row>
            </AvForm>
          </div>
          <div className="page-footer">
            <button
              type="button"
              className="btn btn-outline-light waves-effect btn-cancel"
              onClick={onHandleCallBack}
            >
              取消
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect btn-save"
              onClick={onHandleCallBack}
            >
              保存
            </button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default AddDirectionalIssuanceSecurities;

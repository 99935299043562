import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";

//scss
import "../../scss/MarketingList.scss";

import classnames from "classnames";
import TemplateCom from "./Com/TemplateCom";
// import MarketingTableCom from "./Com/MarketingTableCom";
import DataTableCom from "./Com/DataTableCom";
import { Link, useHistory } from "react-router-dom";
import {
  getMarketingPlans,
  getPlanStatus,
  deleteMarketingPlans,
} from "../../../../helpers/mallbackend_helper";
import { toast } from "react-toastify";
import { Input } from "antd";

const MarketingList = () => {
  const [activeTab, setActiveTab] = useState("全部");
  const history = useHistory();

  //列表查询
  const [marketingList, setMarketingList] = useState<any>([]);
  const [tempList, setTempList] = useState<any>([]);
  const [planStatus, setPlanStatus] = useState<any>([]);
  const [keyword, setKeyWord] = useState(""); //关键词搜索
  const [type, setType] = useState("all"); //列表计划类型筛选
  const [status, setStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(1); //页码
  const [pageSize, setPageSize] = useState(10); //每页条数
  const [total, setTotal] = useState(0); //列表总数
  const pageOptions: any = {
    page_size: 10, //每页条数
    page_number: 1, //页码
    total: total, // 总数
    custom: true,
  };
  const [pagination, setPagination] = useState<any>(pageOptions); //分页当前数据项
  const toggle = (tab: any) => {
    if (activeTab !== tab.status_cn) {
      setActiveTab(tab.status_cn);
      setPageNumber(1);
      setStatus(tab.status);
    }
  };

  //获取列表营销计划列表
  const getPlanStatusList = async () => {
    const type_value = type === "all" ? null : type;
    const res = await getPlanStatus({ type: type_value, keyword });
    if (res.code === 200) setPlanStatus(res.data);
  };

  //获取营销计划列表
  const getMarketing = async (
    page?: number,
    sizePerPage?: number,
    is_template?: string
  ) => {
    if(sizePerPage) setPageSize(sizePerPage);
    let apiFilter = {
      page_size: sizePerPage || pageSize,
      page_number: page || pageNumber,
      type: type === "all" ? null : type,
      keyword: keyword,
      status: status === "all" ? null : status,
    };
    if (is_template === "is_template") {
      apiFilter = {
        page_number: 0,
        page_size: 0,
        type: null,
        keyword: "",
        status: "is_template",
      };
    }

    const res = await getMarketingPlans(apiFilter);
    if (res.code === 200) {
      if (is_template === "is_template") {
        setTempList(res.data);
      } else {
        setMarketingList(res.data);
        setTotal(res.total);
      }
    }
  };
  //营销计划删除
  const marketingDel = async (id: string) => {
    const res = await deleteMarketingPlans(id);
    if (res.code === 200) {
      toast.success("删除成功", {
        position: "top-right",
        autoClose: 2000,
      });
      getPlanStatusList();
      getMarketing();
    }
  };
  const updateMarketingInfo = (id: string, type: string) => {
    history.push(`/addCustomSchedule?id=${id}&type=${type}`);
  };
  const pagePros = {
    marketingList: marketingList,
    option: pagination,
    total: total,
    pageNumber: pageNumber,
    pageSize: pageSize,
    setPageNumber: setPageNumber,
    getMarketing: getMarketing,
    marketingDel: marketingDel,
    updateMarketingInfo: updateMarketingInfo,
  };
  //搜索
  const handleSearch = () => {
    getMarketing();
    getPlanStatusList();
  };
  useEffect(() => {
    pageOptions.total = total;
  }, [total]);

  useEffect(() => {
    getMarketing();
    getPlanStatusList();
  }, [type, status]);

  useEffect(() => {
    getMarketing(undefined, undefined, "is_template");
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>客户运营-精准营销</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody className="add-schedule-row-box">
              <div style={{ width: "17rem", height: "10rem", display: "flex" }}>
                <Link to="/addCustomSchedule?type=add">
                  <div className="add-schedule">
                    <i className="bx bx-plus"></i>
                    {/* <img className="add-icon" src={addIcon} alt="" /> */}
                    <span>添加自定义计划</span>
                  </div>
                </Link>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "-160px 0 0 0",
                  padding: " 0 0 0 16.5rem",
                }}
              >
                <div className="tempList">
                  <TemplateCom
                    tempList={tempList}
                    updateMarketing={updateMarketingInfo}
                  ></TemplateCom>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Card className="search-card-box">
                    <CardBody style={{ padding: "1.25rem !important" }}>
                      <Row>
                        <Col lg={6}>
                          <div className="select-box">
                            <span>计划类型：</span>
                            <select
                              className="form-select"
                              value={type}
                              onChange={(e) => {
                                setType(e.target.value);
                              }}
                            >
                              <option value="all">全部</option>
                              <option value="once">单次营销</option>
                              <option value="customer_behavior">
                                客户行为触发
                              </option>
                              <option value="repeat">定期重复</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="activity-box">
                            <div className="search-box position-relative">
                              <Input
                                className="div-search-box"
                                size="large"
                                placeholder="请输入活动名称"
                                prefix={<SearchOutlined />}
                                onChange={(e) => {
                                  setKeyWord(e.target.value);
                                }}
                                onPressEnter={handleSearch}
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-light waves-effect btnSearch"
                              onClick={handleSearch}
                            >
                              搜索
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="tabs-card">
                    <CardBody>
                      <Nav tabs>
                        {/* <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "all",
                            })}
                            onClick={() => {
                              toggle("all");
                            }}
                          >
                            全部({total})
                          </NavLink>
                        </NavItem> */}
                        {planStatus?.map((item: any, index: number) => {
                          return (
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === item.status_cn,
                                })}
                                onClick={() => {
                                  toggle(item);
                                }}
                              >
                                {item.status_cn}({item.count})
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                    </CardBody>
                  </Card>
                  <Card className="table-card">
                    <CardBody>
                      <DataTableCom {...pagePros}></DataTableCom>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarketingList;

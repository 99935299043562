import React, { useEffect, useState } from "react";
//scss
import "../../../scss/Template.scss";

//images

const TemplateCom = (props: any) => {
  const { tempList,updateMarketing } = props;
  const [list,setList] = useState([]);
  const handelUsingTemp = (id:string) => {
    updateMarketing(id, "copy_template");
  };
  useEffect(()=>{
    if(tempList) {
      setList(tempList);
    }
  },[tempList])
  return (
    <React.Fragment>
      <>
        {list?.map((item: any, index: number) => {
          return (
            <div className="template-card" key={index}>
              <div className="title">{item.name}</div>
              <div className="body">
                {item.description}
              </div>
              <button onClick={()=>{handelUsingTemp(item.plan_id)}}>使用模板</button>
            </div>
          );
        })}
      </>
    </React.Fragment>
  );
};

export default TemplateCom;

import React from "react";
import ReactApexChart from "react-apexcharts";

const Spinearea = () => {
  const series = [
    {
      name: "访问",
      data: [34, 40, 28, 52, 42, 109, 300],
    },
    {
      name: "浏览",
      data: [32, 60, 534, 46, 34, 52, 41],
    },
    {
      name: "收藏",
      data: [46, 45, 285, 64, 41, 81, 46],
    },
    {
      name: "加购",
      data: [79, 67, 206, 55, 149, 64, 28],
    },
    {
      name: "下单",
      data: [77, 99, 16, 75, 364, 70, 37],
    },
    {
      name: "购买",
      data: [32, 60, 34, 46, 34, 252, 41],
    },
    {
      name: "客单价",
      data: [23, 436, 19, 86, 75, 67, 99],
    },
  ];

  const options: Object = {
    dataLabels: {
      enabled: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    title: { text: "所有用户近七日行为", align: "left" },
    colors: ["#556ee6", "#34c38f", "#f46a6a", "#50a5f1", "#f1b44c", "#EF634E", "#9062FF"],
    xaxis: {
      type: "datetime",
      categories: [
        "2018-09-19T00:00:00",
        "2018-09-19T01:30:00",
        "2018-09-19T02:30:00",
        "2018-09-19T03:30:00",
        "2018-09-19T04:30:00",
        "2018-09-19T05:30:00",
        "2018-09-19T06:30:00",
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
    />
  );
};

export default Spinearea;

import { Dropdown, MenuProps, Space, Typography } from "antd";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
const TableCom = () => {
  const history = useHistory();
  const columns = [
    {
      dataField: "name",
      text: "人群名称",
      sort: false,
    },
    {
      dataField: "definition",
      text: "人群定义",
      sort: false,
    },
    {
      dataField: "number",
      text: "人数",
      sort: false,
    },
    {
      dataField: "Operator",
      text: "操作人",
      sort: false,
    },
    {
      dataField: "createStore",
      text: "创建门店",
      sort: false,
    },
    {
      dataField: "updateTime",
      text: "更新时间",
      sort: false,
    },
    {
      dataField: "operation",
      text: "操作",
      sort: false,
    },
  ];
  const handleMenuClick = (e:any) => {
    if (e.key === "1") {
      history.push({
        pathname: `/addDirectionalIssuanceSecurities`,
      });
    } else if (e.key === "2") {
      history.push({
        pathname: `/addMessagePush`,
      });
    } else {
      console.log(`发送积分`);
    }
  };
  // 工具栏
  const tools = (id: any) => {
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: "定向发券",
      },
      {
        key: "2",
        label: "发送短信",
      },
      {
        key: "3",
        label: "发放积分",
      },
    ];
    return (
      <>
        <Dropdown
          menu={{
            items,
            selectable: true,
            onClick: (e) => {
              handleMenuClick(e);
            },
          }}
        >
          <Typography.Link>
            <Space style={{color:'#7a7fdc',paddingRight:'15px',fontSize:'12px'}}>
              人群应用
            </Space>
          </Typography.Link>
        </Dropdown>
        |
        <button type="button" className="btn btn-link btn-rounded waves-effect">
          编辑
        </button>
        |
        <button type="button" className="btn btn-link btn-rounded waves-effect">
          删除
        </button>
      </>
    );
  };
  const dataList = [
    {
      name: "青少年",
      definition: "年龄在15-18岁之前；性别为男",
      number: "298",
      Operator: "张三",
      createStore: "总部",
      updateTime: "2023-07-04 14:00:00",
      operation: tools(1),
    },
    {
      name: "女性用户",
      definition: "年满16周岁；性别为女",
      number: "3188",
      Operator: "张五",
      createStore: "总部",
      updateTime: "2023-07-04 14:00:00",
      operation: tools(1),
    },
    {
      name: "老用户",
      definition: "开通会员满1年的客户",
      number: "8575",
      Operator: "张三",
      createStore: "总部",
      updateTime: "2023-07-04 14:00:00",
      operation: tools(1),
    },
    {
      name: "高消费用户",
      definition: "近一个月内消费金额满10000会员用户",
      number: "28",
      Operator: "张三",
      createStore: "总部",
      updateTime: "2023-07-04 14:00:00",
      operation: tools(1),
    },
  ];
  const pageOptions: any = {
    sizePerPage: 5,
    totalSize: dataList.length, // replace later with size(customers),
    custom: true,
  };
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  return (
    <React.Fragment>
      <div className="container-fluid dataTableCom">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider pagination={paginationFactory(pageOptions)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="name"
                      columns={columns}
                      data={dataList}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="text-md-right ms-auto">
                                <span
                                  style={{
                                    position: "relative",
                                    top: "0.6rem",
                                    right: "0.8rem",
                                  }}
                                >
                                  每页 5 条，共 4 条
                                </span>
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TableCom;

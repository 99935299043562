import {
  Input,
  Pagination,
  PaginationProps,
  Space,
  Button,
  ConfigProvider,
} from "antd";
import locale from "antd/locale/zh_CN";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Row, Col, Container, Modal } from "reactstrap";
import Table, { ColumnsType } from "antd/es/table";
import { getPlanHistories } from "../../../../helpers/mallbackend_helper";
import { FileSearchOutlined } from "@ant-design/icons";
import "../../scss/MarketingPlanHistoriesLit.scss";
import AddPlanTaskHistoryModal from "./AddPlanTaskHistoryModal";
import { useLocation } from "react-router-dom";
import SearchCom from "./SearchCom";
import _ from "lodash";
import { getCustomerPlanHistory } from "src/helpers/mallbackend_helper";
import DisplayCustomersList from "src/components/DisplayCustomersList";

const marketingPlanHistoriesList = () => {
  interface DataType {
    plan_history_id: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }
  let location = useLocation();
  let plan_id = location.state?.plan_id || "";
  let name = location.state?.name || "";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [search_open, setSearchOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [planHistoryId, setPlanHistoryId] = useState("");
  const [keyword, setKeyWord] = useState(name); //关键词搜索
  const [planId, setPlanId] = useState(plan_id); 
  const [planName, setPlanName] = useState(name); 
  //目标客户列表分页
  const [customersTotal,setCustomersTotal] = useState(0);
  const [customerSize,setCustomerSize] = useState(5);
  const [customerNumber,setCustomerNumber] = useState(1);
  const [showCustomer, setShowCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);

  //获取目标客户列表
  const getCustomers = async (id: string,data?:any) => {
    let pid = id;
    if (!pid) pid = planHistoryId;
    const params = {
      pageSize:data?.pageSize || customerSize,
      pageNumber:data?.pageNumber || customerNumber,
      keyword:data?.keyword,
    };
    const result = await getCustomerPlanHistory(pid,params);
    if (result.code === 200) {
      setCustomers(result.data);
      setShowCustomer(true);
      setCustomersTotal(result.total);
    }
  };
  const customerProps = {
    isOpen: showCustomer,
    toggle: ()=>{setShowCustomer(false)},
    data: customers,
    planId,
    customerSize,
    customerNumber,
    getList: getCustomers,
    dataTotal:customersTotal,
  };
  const pageOptions: any = {
    page_size: 5, // ，每页条数
    page_number: 1, //页码
    total: 0, //总数
  };
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState<any>(pageOptions); //分页当前数据项
  //分页获取计划任务历史记录列表
  const GetList = async (pageNumber?: number, plan_id?: string) => {
    let id = planId;
    if (plan_id) id = plan_id;
    const params = {
      page_number: pageNumber || pagination.page_number,
      page_size: pagination.page_size,
      keyword,
      plan_id: id,
    };
    const res = await getPlanHistories(params);
    if (res.code === 200) {
      setData(res.data);
      setPagination({ ...pagination, total: res.total });
    }
  };
  //任务历史详情
  const info = (id: string, plan_history_id: string) => {
    setOpen(true);
    setCurrentId(id);
    setPlanHistoryId(plan_history_id);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "活动名称",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "计划类型",
      dataIndex: "type_cn",
      key: "type_cn",
      width: 200,
    },
    {
      title: "活动时间",
      dataIndex: "trigger_time",
      key: "trigger_time",
      width: 250,
    },
    {
      title: "覆盖客户",
      key: "target_customers_count",
      dataIndex: "target_customers_count",
      width: 150,
      render: (e, item: any) => {
        return (
          <>
            <span
              style={{ color: "#4576F9" }}
              onClick={() => {
                getCustomers(item.plan_id);
                setPlanHistoryId(item.plan_history_id);
              }}
            >
              {e}
            </span>
          </>
        );
      },
    },
    {
      title: "执行完成客户总数",
      key: "success_customers_count",
      render: (item) => {
        if (
          _.isEmpty(item?.target_customers_count) &&
          _.isEmpty(item?.failed_customers_count)
        ) {
          return item.target_customers_count - item.failed_customers_count;
        } else return "-";
      },
    },
    {
      title: "执行失败客户总数",
      key: "failed_customers_count",
      dataIndex: "failed_customers_count",
    },
    {
      title: "是否完成",
      key: "is_completed",
      dataIndex: "is_completed",
      width: 150,
      render: (item) => {
        if (item)
          return <span className="bg-success status-style">已完成</span>;
        else return <span className="bg-warning status-style">未完成</span>;
      },
    },
    {
      title: "操作",
      key: "action",
      width: 100,
      render: (item) => (
        <Space size="middle">
          <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              info(item.plan_id, item.plan_history_id);
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];
  const showTotal: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pagination.page_size} 条，共 ${pagination.total} 条`;

  const toggle = () => {
    setOpen(false);
  };
  const toggle2 = () => {
    setOpen2(false);
  };
  const search_toggle = () => {
    setSearchOpen(false);
  };
  const clear = () => {
    setPlanId("");
    setPlanName("");
  };
  const onOk = (planId: string, name: string) => {
    clear();
    setKeyWord(name);
    search_toggle();
    GetList(1, planId);
  };
  //模态框属性
  const modalOpts2 = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open2,
    centered: true,
    autoFocus: true,
    toggle: toggle2,
    className: "add-coupon-modal",
  };

  const searchModalOptions = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: search_open,
    centered: true,
    autoFocus: true,
    toggle: search_toggle,
    onOk: onOk,
    className: "add-coupon-modal",
  };
  //搜索框双击事件
  const handleDoubleClick = (e: any) => {
    setSearchOpen(true);
  };
  useEffect(() => {
    GetList();
  }, []);
  //搜索
  const handleSearch = () => {
    GetList();
  };
  //分页更改
  const onPageChange = (pageNumber: number, page: number) => {
    setPagination({ ...pagination, pageNumber });
    GetList(pageNumber);
  };
  return (
    <React.Fragment>
      <div className="page-content plan-history-content">
        <MetaTags>
          <title>客户运营-营销计划任务历史记录</title>
        </MetaTags>
        <Container fluid>
          <Card className="plan-histories-card">
            <CardBody>
              <Row style={{ height: "100px" }}>
                <Col lg={6}>
                  <div style={{ marginTop: "30px" }}>
                    <Input
                      style={{ width: "380px", height: "40px" }}
                      placeholder="请输入活动名称"
                      value={keyword}
                      onChange={(e) => {
                        setKeyWord(e.target.value);
                        clear();
                      }}
                      onPressEnter={handleSearch}
                      onDoubleClick={(e: any) => {
                        handleDoubleClick(e);
                      }}
                    />
                    {/* <ClearOutlined onClick={()=>{
                      setKeyWord("");
                      handleSearch
                    }} /> */}
                    <FileSearchOutlined
                      onClick={(e: any) => {
                        handleDoubleClick(e);
                      }}
                    />
                    <SearchCom {...searchModalOptions}></SearchCom>
                  </div>
                </Col>
              </Row>
              <AddPlanTaskHistoryModal
                toggle={toggle}
                isOpen={open}
                currentId={currentId}
                planHistoryId={planHistoryId}
              ></AddPlanTaskHistoryModal>
              <Modal backdrop="static" {...modalOpts2} toggle={toggle2}>
                <div className="modal-header">
                  已选优惠券列表
                  <button
                    type="button"
                    onClick={toggle2}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row className="row-box">
                    <div className="col-lg-10">
                      <span style={{ color: "red" }}>*</span>
                      只可添加在发放渠道范围内、且在有效期内的优惠前。在活动中或有效期在活动开始前的优惠券，将无法参与发放
                    </div>
                    <div className="col-lg-2">
                      <Button
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        123
                      </Button>
                    </div>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-light waves-effect btn-cancel"
                    onClick={toggle2}
                  >
                    取消
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-save"
                  >
                    确认
                  </button>
                </div>
              </Modal>
              <ConfigProvider locale={locale}>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  rowKey={(record) => record.plan_history_id}
                />
                <DisplayCustomersList {...customerProps} />
                <div style={{ height: "50px", marginTop: "15px" }}>
                  <Pagination
                    showQuickJumper
                    defaultPageSize={pageOptions.sizePerPage}
                    showTotal={showTotal}
                    total={pagination.total}
                    onChange={onPageChange}
                    defaultCurrent={1}
                  />
                </div>
              </ConfigProvider>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default marketingPlanHistoriesList;

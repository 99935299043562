import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "reactstrap";

import locale from "antd/locale/zh_CN";
import { SearchOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Table,
} from "antd";

const DisplayCustomersList = (props: any) => {
  const { toggle, data, getList, planId, isOpen,dataTotal } = props;
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyWord] = useState(""); //关键词搜索
  const [id,setId] = useState("");

  //搜索
  const handleSearch = () => {
    getList(id,{pageSize,pageNumber,keyword});
  };
  //分页更改:分页条数，页码
  const onPageChange = (pageNumber: number, pageSize: number) => {
    getList(id,{pageSize,pageNumber,keyword});
  };
  useEffect(() => {
    setDataList(data);
    setOpen(isOpen);
    setTotal(dataTotal);
    setId(planId);
  }, [data, isOpen,dataTotal,planId]);

  const pageOptions: any = {
    pageSize: pageSize, // ，每页条数
    pageNumber: pageNumber, //页码
    total: total, //总数
    custom: true,
  };
  const showTotal: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pageOptions.pageSize} 条，共 ${total} 条`;

  interface DataType {
    plan_id: string;
    name: string;
    type_cn: string;
    trigger_time: string;
    target_is_all_customer_cn: string;
    status_cn: string;
    created_at: string;
  }
  const dateTime = "YYYY-MM-DD HH:mm:ss";
  const modalOpts = {
    size: "ml", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open,
    centered: true,
    autoFocus: true,
    toggle: toggle,
    className: "add-coupon-modal task-history-modal",
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "昵称",
      dataIndex: "nickname",
      key: "nickname",
      width: 200,
    },
    {
      title: "性别",
      dataIndex: "gender_cn",
      key: "gender_cn",
      width: 200,
    },
    { 
      title: "手机号",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 200,
    },
  ];
  return (
    <React.Fragment>
      <Modal backdrop="static" {...modalOpts} toggle={toggle} style={{width:'45%',}}>
        <div className="modal-header">
          客户列表
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body " style={{height:'39vh'}}>
          <div className="container-fluid dataTableCom">
            <Row>
              <Col lg={3} style={{ top: "-9px" }}>
                <div className="search-box position-relative">
                  <Input
                    className="div-search-box"
                    size="large"
                    placeholder="请输入关键字"
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      setKeyWord(e.target.value);
                    }}
                    onPressEnter={handleSearch}
                  />
                </div>
              </Col>
              <Col lg={9} style={{ top: "-2px" }}>
                <div className="activity-box">
                  <button
                    type="button"
                    className="btn btn-outline-light waves-effect btnSearch"
                    onClick={handleSearch}
                  >
                    搜索
                  </button>
                </div>
              </Col>
            </Row>

            <ConfigProvider locale={locale}>
              <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(record) => record.plan_id}
                pagination={false}
              />
              <Pagination
                showQuickJumper
                defaultPageSize={pageOptions.pageSize}
                showTotal={showTotal}
                total={pageOptions.total}
                onChange={onPageChange}
                defaultCurrent={1}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-light waves-effect btn-cancel"
            onClick={toggle}
          >
            关闭
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default DisplayCustomersList;

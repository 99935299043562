import React, { useState } from "react";
import MeaTag from "react-meta-tags";
import {
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import "../scss/AddMessagePush.scss";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";

const AddMessagePush = () => {
  const [activeTabProgress, setActiveTabProgress] = useState(1);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const toggleTabProgress = (tab: any) => {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTabProgress(tab);
      }
    }
  };
  const history = useHistory();
  const onHandleCallBack = () => {
    history.push("/SMSManagement");
  };
  const onToggle = () => {
    setIsOpen(false);
  };
  //模态框属性
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: isOpen,
    centered: true,
    autoFocus: true,
    toggle: onToggle,
    className: "add-item-modal",
  };
  const columns = [
    {
      dataField: "name",
      text: "短信模板内容",
      sort: false,
    },
    // {
    //   dataField: "definition",
    //   text: "剩余短信数",
    //   sort: false,
    // },
    {
      dataField: "number",
      text: "已发送短信数",
      sort: false,
    },
    {
      dataField: "Operator",
      text: "发送成功数",
      sort: false,
    },
    {
      dataField: "operation",
      text: "操作",
      sort: false,
    },
  ];

  const handleClick = (id: any) => {
    const text = dataList[id].name;
    setMessage(text);
    onToggle();
  };
  // 工具栏
  const tools = (id: any) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-link btn-rounded waves-effect"
          onClick={() => {
            handleClick(id);
          }}
        >
          选择
        </button>
      </>
    );
  };
  const dataList = [
    {
      name: "[魔云科技]：亲爱的XXX，中秋节到了，我们为您送上一份大礼，戳https://www.baidu.com领取，回TD退订",
      // definition: "88",
      number: "8",
      Operator: "8",
      operation: tools(0),
    },
    {
      name: "[魔云科技]：亲爱的XXX，国庆节到了，我们为您送上一份大礼，戳https://www.baidu.com领取，回TD退订",
      // definition: "188",
      number: "68",
      Operator: "68",
      operation: tools(1),
    },
    {
      name: "[魔云科技]：亲爱的XXX，圣诞节到了，我们为您送上一份大礼，戳https://www.baidu.com领取，回TD退订",
      // definition: "588",
      number: "98",
      Operator: "98",
      operation: tools(2),
    },
  ];
  const pageOptions: any = {
    sizePerPage: 5,
    totalSize: dataList.length, // replace later with size(customers),
    custom: true,
  };
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const handleSend = () => {
    toast.success("发布成功！");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MeaTag>
          <title>新建消息推送</title>
        </MeaTag>
        <Container fluid className="messagePushContainer">
          <div className="page-title-left">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/SMSManagement">短信营销管理</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="/addMessagePush">消息推送</Link>
              </BreadcrumbItem>
            </ol>
          </div>
          {/* <Row className="row-box div-header">
            <div className="col-lg-6 div-first div-active">编辑消息</div>
            <div className="col-lg-6  div-second">2. 发送设置</div>
          </Row> */}
          <div className="div-content">
            <AvForm
              className="needs-validation"
              style={{
                position: "relative",
                top: " 20px",
                padding: "0px 20px",
              }}
            >
              <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom01">
                    <span style={{ color: "#e5001d" }}>* </span>推送人群：</Label>
                </div>
                <div className="col-lg-3" style={{top:'-10px'}}>
                  <select
                    className="form-control"
                    name="choices-single-no-search"
                    id="choices-single-no-search"
                  >
                    <option value="0">青少年</option>
                    <option value="0">女性用户</option>
                    <option value="0">老用户</option>
                    <option value="0">高消费用户</option>
                  </select>
                </div>
              </Row>
              <Row className="row-box" style={{ marginTop: "20px" }}>
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom01">
                    {" "}
                    <span style={{ color: "#e5001d" }}>* </span>推送名称：
                  </Label>
                </div>
                <div className="col-lg-11">
                  <AvField
                    name="firstname"
                    placeholder="限15个字以内，仅用于商家记录，客户不会看到这"
                    type="text"
                    errorMessage="Enter First Name"
                    className="form-control push-name"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                  />
                </div>
              </Row>
              <Row className="row-box" style={{ marginTop: "20px" }}>
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom01">
                    {" "}
                    <span style={{ color: "#e5001d" }}>* </span>短信内容：
                  </Label>
                </div>
                <div className="col-lg-7">
                  <div className="sms-template-header">
                    <span
                      className="span-style"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      选择模板{" "}
                    </span>{" "}
                    |<span> 存为新模板 </span> |<span> 测试发送</span>
                  </div>
                  <textarea
                    id="basicpill-address-input"
                    className="form-control"
                    rows={5}
                    value={message}
                    placeholder="请输入短信内容"
                  ></textarea>
                  <div className="div-sms-tips">
                    已输入<span className="span-style"> {message.length} </span>
                    字（含签名和尾缀），按
                    <span className="span-style"> 1 </span>条短信计费
                  </div>
                </div>
              </Row>
              {/* <Row className="row-box">
                <div className="col-lg-1">
                  <Label htmlFor="validationCustom01">
                    {" "}
                    <span style={{ color: "#e5001d" }}>* </span>筛选条件：
                  </Label>
                </div>
                <div className="col-lg-3  div-mobile-bg">
                    
                </div>
                <div className="col-lg-7">
                  <Row className="row-box">
                    <div className="col-lg-2">
                      <Label htmlFor="validationCustom01">
                        {" "}
                        <span style={{ color: "#e5001d" }}>* </span>
                        短信内容：
                      </Label>
                    </div>
                    <div className="col-lg-5">
                      <div className="sms-template-header">
                        <span className="span-style">选择模板 </span> |
                        <span> 存为新模板 </span> |<span> 测试发送</span>
                      </div>
                      <textarea
                        id="basicpill-address-input"
                        className="form-control"
                        rows={5}
                        placeholder="请输入短信内容"
                      ></textarea>
                      <div className="div-sms-tips">
                        已输入<span className="span-style"> 10 </span>
                        字（含签名和尾缀），按
                        <span className="span-style"> 1 </span>条短信计费
                      </div>
                    </div>
                  </Row>{" "}
                  <Row className="row-box" style={{ marginTop: "20px" }}>
                    <div className="col-lg-2">
                      <Label htmlFor="validationCustom01"> 短信签名：</Label>
                    </div>
                    <div className="col-lg-5" style={{ fontSize: "12px" }}>
                      <div>
                        【有赞】<span className="span-style">刷新 </span>|
                        <span className="span-style"> 修改 </span>
                        （还能修改2次）
                      </div>
                      <div style={{ color: "red", padding: "10px 0" }}>
                        短信签名不得包含有赞，请前往修改
                      </div>
                      <div style={{ margin: "30px 0" }}>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="formrow-customCheck"
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="formrow-customCheck"
                          style={{ marginLeft: "10px" }}
                        >
                          同意{" "}
                          <span className="span-style">《短信充值协议》</span>
                        </Label>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row> */}
            </AvForm>
          </div>{" "}
          <div className="page-footer">
            <button
              type="button"
              className="btn btn-outline-light waves-effect btn-cancel"
              onClick={onHandleCallBack}
            >
              取消
            </button>
            <button
              type="submit"
              className="btn btn-primary waves-effect btn-save"
              onClick={handleSend}
            >
              发送
            </button>
          </div>
          <Modal backdrop="static" {...modalOpts} toggle={onToggle}>
            <div className="modal-header">
              <h5 className="modal-title mt-0">选择短信模板</h5>
              <button
                type="button"
                onClick={onToggle}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row className="row-box">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={dataList}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <span
                                      style={{
                                        position: "relative",
                                        top: "0.6rem",
                                        right: "0.8rem",
                                      }}
                                    >
                                      共 15 条，每页 5 条
                                    </span>
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Row>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-light waves-effect btn-cancel"
                onClick={onToggle}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect btn-save"
              >
                确认
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddMessagePush;

//登录
export const POST_MALL_LOGIN = "/api/mall/admin/v1/oauth/token"
//精准营销
export const Marketing_Plans = "/api/mall/admin/v1/marketing/plans"
//获取营销计划状态列表
export const Marketing_Plans_Status = "/api/mall/admin/v1/marketing/plans_status"
//获取营销计划状态列表
export const Plans_Status = "/api/mall/admin/v1/marketing/plans_status"
//获取用户特征标签
export const User_features_Tags = "/api/mall/admin/v1/common/dicts/types/tree"
// 获取用户特征标签值
export const dicts = "/api/mall/admin/v1/common/dicts/values/tree"
//获取用户特征标签
export const feature_customers = "/api/mall/admin/v1/marketing/features/customers"
//获取卡券类型列表
export const GetCategoryList= "/common/v2/categorys";
//获取卡券列表
export const GetBatchList = "/mall/v2/coupon/batchs";
//分页获取营销计划历史记录
export const GetPlanHistories = "/api/mall/admin/v1/marketing/plan_histories"
//获取计划任务历史记录列表
export const GetPlanTaskHistories = "/api/mall/admin/v1/marketing/plan_task_histories";


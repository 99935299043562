import React from "react";
import MetaTag from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TableCom from "./Table";
import { Link } from "react-router-dom";

const CustomClusteringList = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag>
          <title>分群管理</title>
        </MetaTag>
        <Container fluid className="customClustering-container">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Card className="search-card-box">
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <Link to="/addCrowd">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect btn-release"
                            >
                              新建人群
                            </button>
                          </Link>
                          {/* <Link to="/addDirectionalIssuanceSecurities">
                            <button
                            style={{margin:'0 17px 0 20px'}}
                              type="button"
                              className="btn btn-primary waves-effect btn-release"
                            >
                              定向发券
                            </button>
                          </Link>{" "}
                          <Link to="/addMessagePush">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect btn-release"
                            >
                              新建消息推送
                            </button>
                          </Link> */}
                        </Col>
                        <Col lg={6}>
                          <div className="activity-box">
                            <div className="search-box position-relative">
                              <input
                                type="text"
                                className="form-control rounded border"
                                placeholder="搜索人群名称或操作人"
                              />
                              <i className="bx bx-search search-icon"></i>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-light waves-effect btnSearch"
                            >
                              搜索
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="table-card">
                    <CardBody>
                      <TableCom></TableCom>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomClusteringList;

import './public-path';
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/index";

function render(props: any) {
  const { container } = props;
  ReactDOM.render(
    <Provider store={configureStore({})}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    container ? container.querySelector('#root') : document.querySelector('#root'));
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() {
  console.log('[marketing] app bootstraped');
}

export async function mount(props: any) {
  console.log('[marketing] props from main framework', props);
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}
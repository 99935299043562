import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FromCom from "../Com/FromCom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
// 【发送消息】
const IssuanceOfEquity = (props: any) => {
  interface Content {
    tasks: [
      {
        type: string;
        name: string;
        value: string;
      }
    ];
  }
  const { curItem, setCurTaskItem, isDisabled } = props;
  const [exec_content, setExecContent] = useState<any>(
    curItem?.taskContent?.exec_content?.tasks[0]
  );
  let content: Content = {
    tasks: [
      {
        type: "send_weixin",
        name: "发送微信",
        value: "发送微信通知",
      },
    ],
  };

  const [channel, setChannel] = useState(exec_content.type);
  const onHandleChange = (e: any) => {
    let name = "发送微信",
      value = "发送微信通知";
    if (e.target.value === "send_sms") {
      name = "发送短信";
      value = "发送短信通知";
    }
    setChannel(e.target.value);
    content.tasks[0].type = e.target.value;
    content.tasks[0].name = name;
    content.tasks[0].value = value;
    const item = {
      ...curItem,
      taskContent: { ...curItem.taskContent, exec_content: content },
    };
    setCurTaskItem(curItem.index, item);
  };
  return (
    <React.Fragment>
      <Card className="equity-card send-message-card ">
        <CardBody>
          <AvForm className="needs-validation message-from">
            <FromCom
              curItem={curItem}
              setCurTaskItem={setCurTaskItem}
              isDisabled={isDisabled}
            ></FromCom>
            <Row className="messageRow">
              <Col>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="validationCustom03"
                    style={{ marginRight: "20px" }}
                  >
                    <span style={{ color: "#e5001d" }}>* </span>通知渠道：
                  </Label>
                  <Row className="notification-channel-row">
                    <Col md={10}>
                      <Card>
                        <CardBody>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="channel"
                            value="send_sms"
                            disabled={isDisabled}
                            checked={channel === "send_sms"}
                            onChange={onHandleChange}
                          />
                          <Label className="label-1">短信通知</Label>
                          <div className="describe-div">
                            教育、医疗、酒类、引导加微信短信容易被运营商屏蔽。
                            <span style={{ color: "#7F5CF3" }}>
                              《规则说明》
                            </span>
                          </div>
                          {/* <div className="binding-div">
                            <div>剩余短信条数不足</div>
                            <Link to={"#"}>去充值</Link>
                          </div> */}
                        </CardBody>
                      </Card>
                    </Col>

                    {/* <Col md={6} xl={5}>
                      <Card>
                        <CardBody>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="channel"
                            value="send_weixin"
                            disabled={isDisabled}
                            checked={channel === "send_weixin"}
                            onChange={onHandleChange}
                          />
                          <Label className="label-1">微信公众号通知</Label>
                          <div className="describe-div">
                            根据
                            <span style={{ color: "#7F5CF3" }}>
                              《微信公众号平台运营规范》
                            </span>
                            ，频繁发送营销类模板消息存在被封禁的风险。
                          </div>
                          <div className="binding-div">
                            <div>未绑定认证的公众号，无法推送消息</div>
                            <Link to={"#"}>去绑定</Link>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default IssuanceOfEquity;

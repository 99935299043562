import React from "react";
import { useState } from "react";

import AddTaskCom from "../Com/AddTaskCom";
// 发送消息
const SendMessageStep = (props:any) => {
  const { activeStep, id } = props;
  let isRemove = false;
  const onHandlePlanClick = (step: any) => {
    if (!isRemove)  props.onHandlePlanClick(step)
  };
  const onHandleRemove = (id: string) => {
    props.onHandleRemove(id);
  };
  return (
    <React.Fragment>
      <div
        className={[
          "plan",
          activeStep === id ? "plan-active" : "plan-default",
        ].join(" ")}
        onClick={() => {
            onHandlePlanClick(id);
        }}
      >
        <i className="bx bx-x"
          onClick={() => {
            isRemove = true;
            onHandleRemove(id);
          }}></i>
        <span className="span-text">立即执行</span>
        <span className="span-text2">发送消息</span>
      </div>
    </React.Fragment>
  );
};
export default SendMessageStep;

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import { AvField } from "availity-reactstrap-validation";
const FromCom = (props: any) => {
  const { curItem, setCurTaskItem,isDisabled } = props;
  const cycleUnits = [
    {
      index: 1,
      label: "分钟",
      value: "minute",
    },
    {
      index: 2,
      label: "小时",
      value: "hour",
    },
    {
      index: 3,
      label: "天",
      value: "day",
    },
    {
      index: 4,
      label: "周",
      value: "week",
    },
    {
      index: 5,
      label: "月",
      value: "month",
    },
    {
      index: 6,
      label: "年",
      value: "year",
    },
  ];
  const [exec_content, setExecContent] = useState<any>(curItem.taskContent);
  const [name, setName] = useState(exec_content?.task_name);
  const [type, setType] = useState(exec_content?.exec_type);
  const [period, setPeriod] = useState(exec_content?.delay_exec_period?.toString());
  const [unit, setUnit] = useState(exec_content?.delay_exec_period_unit);
  const [time, setTime] = useState(exec_content?.delay_exec_time);

  useEffect(() => {
    const tasks = {
      exec_type: type, // 执行类型，immediately：上一任务完成后立即执行，delay：延时
      task_name: name,
      delay_exec_period: period?.toString(),
      delay_exec_period_unit: unit,
      delay_exec_time: time,
    };
    // onHandleFromChildValue(tasks);
    
    const item = {...curItem,taskContent:{...curItem.taskContent,...tasks}};
    setCurTaskItem(curItem.index,item);
  }, [name, type, period, unit, time]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <Label htmlFor="validationTaskName" style={{ marginRight: "20px" }}>
              <span style={{ color: "#e5001d" }}>* </span>任务名称：
            </Label>
            <AvField
              name="taskName"
              placeholder="任务名称"
              type="text"
              errorMessage="请输入任务名称"
              className="form-control taskName"
              value={name}
              validate={{ required: { value: true } }}
              disabled={isDisabled}
              onChange={(e:any)=>{
                setName(e.target.value);
              }}
              id="validationTaskName"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="executionTime-row">
        <Col>
          <FormGroup className="mb-3">
            <Label style={{ marginRight: "20px" }}>
              <span style={{ color: "#e5001d" }}>* </span>执行时间：
            </Label>
            <input
              className="form-check-input"
              type="radio"
              name="executionTime"
              // defaultChecked
              checked={type === 'immediately'}
              disabled={isDisabled}
              value='immediately'
              onChange={(e:any) => {
                setType(e.target.value);
              }}
            />
            <Label className="label-1">上一步完成后立即执行</Label>
            <div style={{ margin: "15px 0 0 100px" }}>
              <input
                className="form-check-input"
                type="radio"
                name="executionTime"
                value='delay'
                checked={type === 'delay'}
                disabled={isDisabled}
                onChange={(e:any) => {
                  setType(e.target.value);
                }}
              />
              <Label className="label-2">上一步完成</Label>
              <Input
                className="form-control  execution-day"
                type="number"
                min={1}
                value={period}
                disabled={type === 'immediately' || isDisabled}
                onChange={(e:any)=>{
                  setPeriod(e.target.value.toString());
                }}
              />
              <select
                className="form-select"
                value={unit}
                disabled={type === 'immediately' || isDisabled}
                onChange={(e: any) => {
                  setUnit(e.target.value);
                }}
              >
                {cycleUnits?.map((v, i) => {
                  return (
                    <option value={v.value} key={i}>
                      {v.label}
                    </option>
                  );
                })}
              </select>
              <Label className="label-3">后</Label>
              <Input
                className="form-control repeat-time"
                type="time"
                disabled={type === 'immediately' || isDisabled}
                id="example-time-input"
                value={time}
                onChange={(e: any) => {
                  setTime(e.target.value);
                }}
              />
              <Label className="label-4">执行</Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FromCom;

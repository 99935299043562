import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import FromCom from "../Com/FromCom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AddCouponModal from "./AddCouponModal";
import AddGiftCardModal from "./AddGiftCardModal";
import moment from "moment";
import { getCategoryList } from "../../../../../helpers/mallbackend_helper";
// 发放权益
const IssuanceOfEquity = (props: any) => {
  interface Content {
    tasks: [
      {
        type: string;
        name: string;
        value: any;
      }
    ];
  }
  interface Tasks {
    exec_type: string;
    task_name: string;
    task_type: string;
    delay_exec_period: string;
    delay_exec_period_unit: string;
    delay_exec_time: string;
    exec_content: Content;
  }

  const { curItem, marketing, isDisabled, setCurTaskItem } = props;
  const task = curItem?.taskContent?.exec_content?.tasks[0];
  const [giftTypeCheck, setGiftType] = useState(task?.type);
  const [integral, setIntegral] = useState(task?.value);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGiftCardModal, setIsOpenGiftCardModal] = useState(false);
  const [voucherTypeData, setVoucherTypeData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]); //卡券类型列表

  let content: Content = {
    tasks: [
      {
        type: "point",
        name: "积分",
        value: "3",
      },
    ],
  };
  //优惠券弹窗确定
  const handleModalSave = (list: any) => {
    const arr = [];
    setIsOpen(false); //关闭弹窗
    const data = [];
    if (categoryData?.length > 0) {
      for (const item of categoryData) {
        const count = list.filter(
          (e: any) => e?.category?.name === item.name
        )?.length;
        const type = {
          id: item.category_id,
          name: item.name,
          count: count | 0,
        };
        data.push(type);
      }
      setVoucherTypeData(data);
    }
    for (const items of list) {
      const {
        batch_id,
        name,
        category,
        limitValue,
        residue_stock,
        couponmallcategory,
        limit_content,
        valid_begin_at,
        valid_end_at,
      } = items;
      const selectedRow = {
        batch_id,
        type: "coupon",
        name,
        value: batch_id,
        category,
        limitValue,
        residue_stock,
        couponmallcategory,
        limit_content,
        valid_begin_at: moment(valid_begin_at).format("YYYY-MM-DD HH:mm:ss"),
        valid_end_at: moment(valid_end_at).format("YYYY-MM-DD HH:mm:ss"),
      };
      arr.push(selectedRow);
    }
    const item = {
      ...curItem,
      taskContent: { ...curItem.taskContent, exec_content: { tasks: arr } },
    };
    setCurTaskItem(curItem.index, item);
  };

  //获取卡券类型列表
  const getCategoryData = async () => {
    const params = { parent_id: "14", valid: true };
    const res = await getCategoryList(params);
    if (res.code === 200 && res.data?.length > 0) {
      setCategoryData(res.data);
    }
  };
  const addCouponModalProps = {
    isOpen,
    categoryData,
    selectedList:curItem?.taskContent?.exec_content?.tasks,
    curItem,
    onOk: handleModalSave,
    toggle: () => {
      setIsOpen(!isOpen);
    },
  };
  const addGiftCardModalProps = {
    isOpen: isOpenGiftCardModal,
    onOk: handleModalSave,
    toggle: () => {
      setIsOpenGiftCardModal(!isOpenGiftCardModal);
    },
  };
  const handelIntegralChange = (e: any) => {
    content.tasks[0].value = e.target.value;
    setIntegral(e.target.value);
    const item = {
      ...curItem,
      taskContent: { ...curItem.taskContent, exec_content: content },
    };
    setCurTaskItem(curItem.index, item);
  };
  const handleTypeChange = (e: any) => {
    const giftType = e.target.value;
    let type = "积分";
    setGiftType(giftType);
    if (giftType === "coupon") type = "优惠券";
    else if (giftType === "gift_card") type = "礼品卡";
    setIntegral(1);
    content.tasks[0].type = giftType;
    content.tasks[0].name = type;
    content.tasks[0].value = giftType !== "point" ? null : 1;
    const item = {
      ...curItem,
      taskContent: { ...curItem.taskContent, exec_content: content },
    };
    setCurTaskItem(curItem.index, item);
  };
  useEffect(() => {
    getCategoryData();
  }, []);
  useEffect(() => {
    const data = [];
    const { taskContent } = curItem;
    if (categoryData?.length > 0) {
      for (const item of categoryData) {
        const count = taskContent?.exec_content?.tasks.filter(
          (e: any) => e?.category?.name === item.name
        )?.length;
        const type = {
          id: item.category_id,
          name: item.name,
          count: count | 0,
        };
        data.push(type);
      }
      setVoucherTypeData(data);
    }
  }, [categoryData]);
  return (
    <React.Fragment>
      <Card className="equity-card">
        <CardBody>
          <AvForm className="needs-validation equity-from">
            {/* 表单内容 */}
            <FromCom
              curItem={curItem}
              isDisabled={isDisabled}
              setCurTaskItem={setCurTaskItem}
            ></FromCom>
            <Row className="giftTypeRow">
              <Col>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="validationCustom03"
                    style={{ marginRight: "20px" }}
                  >
                    <span style={{ color: "#e5001d" }}>* </span>赠送类型：
                  </Label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="giftType"
                    value="point"
                    disabled={isDisabled}
                    checked={giftTypeCheck === "point"}
                    onChange={handleTypeChange}
                  />
                  <Label className="integral-label">积分</Label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="giftType"
                    value="double-point"
                    disabled={isDisabled}
                    checked={giftTypeCheck === "double-point"}
                    onChange={handleTypeChange}
                  />
                  <Label className="integral-label">多倍积分</Label>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="giftType"
                    value="coupon"
                    disabled={isDisabled}
                    checked={giftTypeCheck === "coupon"}
                    onChange={handleTypeChange}
                  />
                  <Label className="integral-label">优惠券</Label>
                  {/* <input
                    className="form-check-input"
                    type="radio"
                    name="giftType"
                    value="gift_card"
                    disabled={isDisabled}
                    checked={giftTypeCheck === 'gift_card'}
                    onChange={handleTypeChange}
                  />
                  <Label className="integral-label">礼品卡</Label> */}
                  <Card className="giftTypeCard">
                    <CardBody>
                      {giftTypeCheck === "point" ? (
                        <div style={{ position: "relative" }}>
                          <span style={{ color: "#e5001d" }}>* </span>
                          <span>赠送客户</span>
                          <Input
                            type="number"
                            min={1}
                            className="form-control"
                            id="specificSizeInputGroupUsername"
                            style={{
                              width: "80px",
                              height: "30px",
                              margin: "0 10px",
                              display: "inline-block",
                            }}
                            value={integral}
                            disabled={isDisabled}
                            onChange={handelIntegralChange}
                          />
                          <span>个积分</span>
                        </div>
                      ) : null}
                      {giftTypeCheck === "double-point" ? (
                        <div style={{ position: "relative" }}>
                          <span style={{ color: "#e5001d" }}>* </span>
                          <span>赠送客户</span>
                          <Input
                            type="number"
                            min={1}
                            className="form-control"
                            id="specificSizeInputGroupUsername"
                            style={{
                              width: "80px",
                              height: "30px",
                              margin: "0 10px",
                              display: "inline-block",
                            }}
                            value={integral}
                            disabled={isDisabled}
                            onChange={handelIntegralChange}
                          />
                          <span>倍积分</span>
                        </div>
                      ) : null}
                      {giftTypeCheck === "coupon" ? (
                        <div>
                          <Button
                            className="add-coupon"
                            disabled={isDisabled}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            添加优惠券
                          </Button>
                          <span>
                            最多支持添加15张优惠券： 
                            <div style={{marginLeft:'100px'}}>
                              {voucherTypeData?.map(
                                (item: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        marginLeft: "10px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      {item.name}
                                      <span
                                        style={{
                                          color: "#8558FA",
                                          margin: "0 10px",
                                        }}
                                      >
                                        {item.count}
                                      </span>
                                      <span>|</span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            {/* 商品券{" "}
                            <span style={{ color: "#8558FA" }}>
                              {productVoucher}{" "}
                            </span>
                            | 兑换券{" "}
                            <span style={{ color: "#8558FA" }}>0 </span> |
                            折扣券 <span style={{ color: "#8558FA" }}>0 </span>{" "}
                            | */}
                          </span>
                          <AddCouponModal
                            {...addCouponModalProps}
                          ></AddCouponModal>
                        </div>
                      ) : null}
                      {/* {giftTypeCheck === "gift_card" ? (
                        <div>
                          <Button
                            className="add-coupon"
                            disabled={isDisabled}
                            onClick={() => {
                              setIsOpenGiftCardModal(true);
                            }}
                          >
                            添加礼品卡
                          </Button>
                          <span>最多支持添加15张礼品卡</span>
                          <AddGiftCardModal
                            {...addGiftCardModalProps}
                          ></AddGiftCardModal>
                        </div>
                      ) : null} */}
                    </CardBody>
                  </Card>
                  {giftTypeCheck === "coupon" ||
                  giftTypeCheck === "gift_card" ? (
                    <div className="div-tips">
                      <span>请至少选择1张优惠券</span>
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default IssuanceOfEquity;

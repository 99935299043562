import React from "react";
import ReactApexChart from "react-apexcharts";

const DashedLine = () => {
  const series = [
    {
      name: "青少年",
      data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
    },
    {
      name: "女性用户",
      data: [136, 421, 610, 422, 513, 218, 629, 537, 386, 511, 932, 635],
    },
    {
      name: "老用户",
      data: [489, 356, 574, 98, 172, 318, 624, 416, 824, 518, 146, 149],
    },
    {
      name: "高消费用户",
      data: [189, 556, 674, 298, 792, 366, 560, 88, 64, 188, 951, 458],
    },
  ];
  const options: Object = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#f46a6a", "#34c38f","#f1b44c"],
    dataLabels: { enabled: !1 },
    stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
    title: { text: "活跃用户画像", align: "left" },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: [
        "01月",
        "02月",
        "03月",
        "04月",
        "05月",
        "06月",
        "07月",
        "08月",
        "09月",
        "10月",
        "11月",
        "12月",
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e: any) {
              return e + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (e: any) {
              return e + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (e: any) {
              return e;
            },
          },
        },
      ],
    },
    grid: { borderColor: "#f1f1f1" },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
    />
  );
};

export default DashedLine;

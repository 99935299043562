import React from "react";
import MetaTag from "react-meta-tags";
import { Card, CardBody, Col, Container, Dropdown, Row } from "reactstrap";
import TableCom from "./Table";
import { Link, useHistory } from "react-router-dom";
import { MenuProps, Typography, Space } from "antd";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const SMSMessage = () => {
  const history = useHistory();
  const columns = [
    {
      dataField: "smsName",
      text: "短信名称",
      sort: false,
    },
    {
      dataField: "name",
      text: "人群名称",
      sort: false,
    },
    {
      dataField: "number",
      text: "总人数",
      sort: false,
    },
    {
      dataField: "successNumber",
      text: "成功发送人数",
      sort: false,
    },
    {
      dataField: "time",
      text: "发送时间",
      sort: false,
    },
    {
      dataField: "createStore",
      text: "推送状态",
      sort: false,
    },
    // {
    //   dataField: "operation",
    //   text: "操作",
    //   sort: false,
    // },
  ];
  const tools = (id: any) => {
    return (
      <>
        <span style={{color: '#1677ff'}}>详情</span>
      </>
    );
  };
  const dataList = [
    {
      smsName: "2023青少年推广短信",
      name: "青少年",
      number: "688",
      successNumber: "688",
      time: "2023//07/06 10:00",
      createStore: <span style={{padding:'5px 15px',background:'#2ab57d',color:'white',borderRadius:'3px'}}>成功</span>,
      operation: tools(1),
    },
    {
      smsName: "2022双十一购物节送福利啦~",
      name: "全部",
      number: "6198",
      successNumber: "6198",
      time: "2022//11/11  11:11",
      createStore: <span style={{padding:'5px 15px',background:'#2ab57d',color:'white',borderRadius:'3px'}}>成功</span>,
      operation: tools(1),
    },
    {
      smsName: "2021年三八妇女节福利派送~",
      name: "女神",
      number: "268",
      successNumber: "268",
      time: "2021//01/06 9:00",
      createStore: <span style={{padding:'5px 15px',background:'#2ab57d',color:'white',borderRadius:'3px'}}>成功</span>,
      operation: tools(1),
    },
  ];
  const pageOptions: any = {
    sizePerPage: 5,
    totalSize: dataList.length, // replace later with size(customers),
    custom: true,
  };
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag>
          <title>短信营销管理</title>
        </MetaTag>
        <Container fluid className="customClustering-container">
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Card className="search-card-box">
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <Link to="/addMessagePush">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect btn-release"
                            >
                              新建消息推送
                            </button>
                          </Link>
                        </Col>
                        <Col lg={6}>
                          <div className="activity-box">
                            <div className="search-box position-relative">
                              <input
                                type="text"
                                className="form-control rounded border"
                                placeholder="搜索"
                              />
                              <i className="bx bx-search search-icon"></i>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-light waves-effect btnSearch"
                            >
                              搜索
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="name"
                                columns={columns}
                                data={dataList}
                                search
                              >
                                {(toolkitProps) => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            // responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="text-md-right ms-auto">
                                          <span
                                            style={{
                                              position: "relative",
                                              top: "0.6rem",
                                              right: "0.8rem",
                                            }}
                                          >
                                            每页 5 条，共 3 条
                                          </span>
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SMSMessage;

import moment from 'moment'
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
// import { genSidebarMenusSuccess } from "../../management/menus/actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postFakeLogin,postJwtLogin,postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { postMallLogin } from "../../../helpers/mallbackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }: any) : any {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(
        fireBaseBackend.loginUser,
        user.username,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response: Promise<any> = yield call(postJwtLogin, {
        username: user.username,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response: Promise<any> = yield call(postFakeLogin, {
        username: user.username,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }else if(process.env.REACT_APP_DEFAULTAUTH === "mall"){
      const response: Promise<any> = yield call(postMallLogin, {
        dlzh: user.username,
        dlmm: user.password,
        grant_type: 'password',
      });
      const rsdata = JSON.parse(JSON.stringify(response));
      if(rsdata.success == false){
        yield put(apiError("账号或者密码有误"));
      }else{
        const token : any = {
          access_token: rsdata.access_token,
          refresh_token: rsdata.refresh_token,
          expire: moment().add(rsdata.expires_in*6, 's')
        };
        localStorage.setItem("APITOKEN", JSON.stringify(token));
        localStorage.setItem("LOOKCORPORATION", JSON.stringify(rsdata.user.corporation));
        localStorage.setItem("authUser", JSON.stringify(rsdata));
        //获取当前用户拥有的菜单(万客GO)
         //前缀
        const prefix = "";
        // const MyMenus = yield call(getMyMenus, { parent_id: 271 })
        const menu : any = [/*{
          id: 1,
          icon: 'laptop',
          name: '首页',
          router: prefix + '/dashboard',
        }*/];
        const loop = (data : any, mpid : any) => {
          data.map((item : any) => {
            if (item.children && item.children.length > 0) {
              loop(item.children, item.menu_id);
            }
            let mdata : any = { id: item.menu_id, name: item.title }
            if (mpid) mdata.mpid = mpid
            if (item.style) mdata.icon = item.style
            if (item.url) mdata.router = prefix + item.url
            mdata.state = {}
            if (item.stateindex) mdata.state.index = item.stateindex
            if (item.functions) mdata.state.functions = item.functions
            menu.push(mdata)
          })
          return;
        }
        // if (MyMenus.data && MyMenus.data.length > 0) {
        //   yield loop(MyMenus.data, null)
        //   localStorage.setItem("MENU", JSON.stringify(menu));
        // }
        // yield put(genSidebarMenusSuccess(menu))
        // //获取用户所有角色 { loginname: user.loginname }
        // const OwnRoles = yield call(getOwnRoles, {loginname: user.username});
        // localStorage.setItem("OWN_ROLE", JSON.stringify(OwnRoles.data));
        yield put(loginSuccess(response));
      }
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }: any) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response: Promise<any> = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response: Promise<any> = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser);
  yield takeLatest(LoginTypes.SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser);
}

export default authSaga;

import axios from "axios";
import lodash from "lodash";
import qs from "qs";
import accessToken from "./jwt-token-access/accessToken";
import moment from "moment";
import { POST_MALL_LOGIN } from "./mall_helper";

//app标识进行base64编码
const signature = btoa(
  process.env.REACT_APP_CLIENTID + ":" + process.env.REACT_APP_SECRET
);
//pass new generated access token here
const token = accessToken;


//apply base url for axios
//万科接口
const Vk_BIZ_URL = process.env.REACT_APP_VK_BIZ_URL;


//默认获取当前域名和端口
export let API_URL: any = `${document.location.protocol}//${document.location.host}`;

//如果不是生产环境，则使用自己配置的本地地址
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV) {
  API_URL = process.env.REACT_APP_BASE_URL
}

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url: string, data: any, config = {}) {
  if (typeof data == "object") {
    if (data instanceof Array) {
      data = [...data];
    } else {
      data = { ...data };
    }
  }
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url: string, data: any, config = {}) {
  if (typeof data == "object") {
    if (data instanceof Array) {
      data = [...data];
    } else {
      data = { ...data };
    }
  }
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

const fetch = (options: any) => {
  let { method = "get", data, fetchType, url } = options;
  let corporation: any = {};
  let token: any = {};
  const corporationStr: any = localStorage.getItem("LOOKCORPORATION");
  const tokenStr: any = localStorage.getItem("APITOKEN");
  if (corporationStr) corporation = JSON.parse(corporationStr);
  if (tokenStr) token = JSON.parse(tokenStr);
  //验证token是否存在 或token是否过期
  if (
    url != POST_MALL_LOGIN &&
    (!token ||
      !moment().isBefore(moment(token.expire).format("YYYY-MM-DD HH:mm:ss")))
  ) {
    console.log("请重新登录");
    // location.href =  "/logout";
    return new Promise((resolve) => {
      return resolve(true);
    });
  } else {
    const headers: any = {
      Accept: "*/*",
      Authorization:
        url == POST_MALL_LOGIN
          ? "Basic " + signature
          : "Bearer " + token.access_token,
      "Corporation-Id":
        url == POST_MALL_LOGIN ? null : corporation.corporation_id,
      // 'Content-Type': url == POST_MALL_LOGIN ? 'application/x-www-form-urlencoded' : null,
    };
    if (url == POST_MALL_LOGIN)
      headers["Content-Type"] = "application/x-www-form-urlencoded";
    switch (method.toLowerCase()) {
      case "get":
        return get(
          `${url}${!lodash.isEmpty(data) ? `?${qs.stringify(data)}` : ""}`,
          { headers: headers, timeout: 30000 }
        );
      case "post":
        let postdata = data;
        if (url == POST_MALL_LOGIN) postdata = qs.stringify(data);
        return post(url, postdata, { headers: headers, timeout: 30000 });
      case "put":
        return put(url, data, { headers: headers, timeout: 30000 });
      case "delete":
        return del(url, { data: data, headers: headers, timeout: 30000 });
      default:
        return axiosApi(options);
    }
  }
};

export async function request(options: any) {
  if (options.url.indexOf("http") > -1) {
  } else if (options.url.indexOf("/v1/") > -1) {
    axiosApi.defaults.baseURL = API_URL;
  } else if (options.url.indexOf("/v2/") > -1) {
    axiosApi.defaults.baseURL = Vk_BIZ_URL;
  }
  return fetch(options)
    .then((response: any) => {
      if (response === true && options.url != POST_MALL_LOGIN) {
        location.href = "/logout";
        return null;
      } else {
        return response;
        // const { statusText, status, data } = response;
        // return {success: true,message: statusText,status,...data};
      }
    })
    .catch((error: any) => {
      const { response } = error;
      let message: any;
      let status: any;
      if (response) {
        status = response.status;
        const { data, statusText } = response;
        message = data.message || statusText;
      } else {
        status = 600;
        message = "Network Error";
      }
      if (status == 401) {
        location.href = "/logout";
        return null;
      } else {
        return { success: false, status, message };
      }
    });
}

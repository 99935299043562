import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Popover,
  PopoverBody,
  Row,
} from "reactstrap";
import "../../../scss/AddItemModal.scss";
import TooltipCom from "../Com/TooltipCom";
//添加商品模态框
const AddItemModal = (props: any) => {
  const { marketing, isOpen, toggle, onOk } = props;
  const [modal_open, setModalOpen] = useState(isOpen);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [currentItemId, setCurrentItemId] = useState(""); //当前操作项
  const [popoverOpen, setPopoverOpen] = useState(false); //是否显示删除询问弹框
  //模态框属性
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: modal_open,
    centered: true,
    autoFocus: true,
    toggle: toggle,
    className: "add-item-modal",
  };
  const tree = [
    //mall: 商场，goods_category: 商品分类, goods: 商品，service: 服务
    {
      id: 1,
      name: "所有商场",
      value: "mall",
      type_CN: "商场",
      type: "12",
      class: "tooltipTop12",
      checked: false,
    },
    {
      id: 2,
      name: "所有商品分类",
      value: "goods_category",
      type_CN: "商品分类",
      type: "13",
      class: "tooltipTop13",
      checked: false,
    },
    {
      id: 3,
      name: "所有商品",
      value: "goods",
      type_CN: "商品",
      type: "14",
      class: "tooltipTop14",
      checked: false,
    },
    {
      id: 4,
      name: "所有服务",
      value: "service",
      type_CN: "服务",
      type: "15",
      class: "tooltipTop15",
      checked: false,
    },
  ];
  const [itemData, setItemData] = useState<any>(tree);
  const selected = [
    {
      name: "已选商场",
      value: "mall",
    },
    {
      name: "已选商品分类",
      value: "goods_category",
    },
    {
      name: "已选商品",
      value: "goods",
    },
    {
      name: "已选服务",
      value: "service",
    },
  ];

  const handleChange = (item: any) => {
    let isChecked = false;
    const arr: any[] = [];
    //重复选择，移除
    if (item.checked) {
      isChecked = false;
      removeCurrentItem(item);
    } else {
      isChecked = true;
      arr.push(item);
      setCheckedList([...checkedList, ...arr]);
    }

    const updatedItemData: any[] = [];
    for (const i of itemData) {
      if (item.id === i.id) {
        i.checked = isChecked;
      }
      updatedItemData.push(i);
    }

    setItemData(updatedItemData);
  };

  //移除当前项
  const removeCurrentItem = (e: any) => {
    let newItems: any = [];
    const attr_index = e.checked ? e.id : e.currentTarget?.dataset.index;
    checkedList?.map((v: any, index: number) => {
      if (v.id != e.id) {
        newItems.push(v);
      }
    });
    setCheckedList(newItems);
    setPopoverOpen(false);
    setCurrentItemId("");

    const updatedItemData: any[] = [];
    for (const i of itemData) {
      if (e.id === i.id) {
        i.checked = false;
      }
      updatedItemData.push(i);
    }

    setItemData(updatedItemData);
  };
  //确定
  const handleSubmit = () => {
    toggle();
    onOk(checkedList);
  };

  useEffect(() => {
    const rules = marketing?.trigger_rules?.rules;
    if (rules && rules?.length > 0) {
      const list:any[] = [];
      const updatedItemData = itemData?.map((item: any) => {
        const rule = rules.find((rule: any) => rule.type === item.value);
        if (rule) {
          list.push({ ...item, checked: true })
          return { ...item, checked: true };
        }
        return item;
      });
      setCheckedList(list);
      setItemData(updatedItemData);
    }
  }, []);
  return (
    <React.Fragment>
      <Modal backdrop="static" {...modalOpts} toggle={toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">添加商品</h5>
          <button
            type="button"
            onClick={() => {
              setModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="row-box">
            <div className="col-lg-4">
              <div className="search-box position-relative">
                <input
                  type="text"
                  className="form-control rounded border search-input"
                  placeholder="请输入服务或商品名称"
                />
                <i className="bx bx-search search-icon"></i>
                <Button className="search-btn" outline>
                  搜索
                </Button>
                <div className="div-tips">
                  <i className="bx bxs-info-circle"></i>
                  选择分类，则该分类下新增的商品，将自动选中
                </div>
                <div className="div-checkbox">
                  {itemData?.map((item: any, index: number) => {
                    return (
                      <div className="form-check mb-2" key={index}>
                        <i className="bx bxs-folder-open"></i>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`formCheck${index}`}
                          checked={item.checked}
                          onChange={() => {
                            handleChange(item);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`formCheck${index}`}
                        >
                          {item.name}
                        </label>
                        <TooltipCom
                          type={item.type}
                          tooltipTopId={item.class}
                        ></TooltipCom>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {selected?.map((item, index) => {
                return (
                  <Card key={index}>
                    <CardHeader>
                      <Row className="row-box">
                        <div className="col-lg-11">{item.name}（0）</div>
                        <div className="col-lg-1">操作</div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {checkedList.filter(
                        (e: { value: string }) => e.value === item.value
                      )?.length > 0 ? (
                        checkedList
                          .filter(
                            (e: { value: string }) => e.value === item.value
                          )
                          ?.map((checkedItem: any, i: number) => {
                            return (
                              <Row key={i}>
                                <div className="col-lg-11">
                                  <i className="bx bxs-folder-open"></i>
                                  {checkedItem.name}
                                </div>
                                <div
                                  className="col-lg-1 del"
                                  id={`Popover_${checkedItem.id}`}
                                  onClick={() => {
                                    if (
                                      currentItemId ==
                                      `Popover_${checkedItem.id}`
                                    ) {
                                      setPopoverOpen(!popoverOpen);
                                      setCurrentItemId(
                                        popoverOpen
                                          ? ""
                                          : `Popover_${checkedItem.id}`
                                      );
                                    } else {
                                      setPopoverOpen(true);
                                      setCurrentItemId(
                                        `Popover_${checkedItem.id}`
                                      );
                                    }
                                  }}
                                >
                                  删除
                                </div>
                                {currentItemId == `Popover_${checkedItem.id}` &&
                                  popoverOpen && (
                                    <Popover
                                      placement="top"
                                      trigger="legacy"
                                      target={`Popover_${checkedItem.id}`}
                                      toggle={() => {
                                        if (popoverOpen == true)
                                          setCurrentItemId("");
                                        setPopoverOpen(!popoverOpen);
                                      }}
                                      isOpen={popoverOpen}
                                      flip
                                    >
                                      <PopoverBody>
                                        <div
                                          style={{ marginBottom: "15px" }}
                                          className="d-flex flex-wrap gap-2"
                                        >
                                          <i
                                            className="bx bx-error-circle"
                                            style={{
                                              color: "#faad14",
                                              fontSize: "20px",
                                            }}
                                          />
                                          确定删除？
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <button
                                            style={{ marginRight: "7px" }}
                                            onClick={() => {
                                              setPopoverOpen(false);
                                              setCurrentItemId("");
                                            }}
                                            type="button"
                                            className="btn btn-secondary btn-sm"
                                          >
                                            取消
                                          </button>
                                          <button
                                            style={{ marginLeft: "7px" }}
                                            data-index={i}
                                            onClick={() => {
                                              removeCurrentItem(checkedItem);
                                            }}
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                          >
                                            确定
                                          </button>
                                        </div>
                                      </PopoverBody>
                                    </Popover>
                                  )}
                              </Row>
                            );
                          })
                      ) : (
                        <Row>
                          <div className="col-lg-12 no-data">
                            暂无数据，请从左侧添加
                          </div>
                        </Row>
                      )}
                      {/* {index === 0 ? (
                        <Row>
                          <div className="col-lg-11">
                            {" "}
                            <i className="bx bxs-folder-open"></i>所有服务
                          </div>
                          <div className="col-lg-1 del">删除</div>
                        </Row>
                      ) : (
                        <Row>
                          <div className="col-lg-12 no-data">
                            暂无数据，请从左侧添加
                          </div>
                        </Row>
                      )} */}
                    </CardBody>
                  </Card>
                );
              })}
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-light waves-effect btn-cancel"
            onClick={toggle}
          >
            取消
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect btn-save"
            onClick={handleSubmit}
          >
            确认
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default AddItemModal;

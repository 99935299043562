import React, { useState } from "react";
import {
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import "../../../scss/AddTaskCom.scss";
const AddTaskCom = (props: any) => {
  const { id, isDisabled } = props;
  const [isPopover, setIsPopover] = useState(false);
  const onHandleClick = (type: string) => {
    const { onHandleAddTask } = props;
    onHandleAddTask(type, id);
    setIsPopover(false);
  };
  return (
    <React.Fragment>
      <div className="div-add-task">
        <i className="bx bx-plus-circle add-task-icon"></i>
        <button
          id={`add_${id}`}
          disabled={isDisabled}
          onClick={() => {
            setIsPopover(!isPopover);
          }}
        >
          {" "}
          添加任务
        </button>
        <i className="right-top"></i>
        <UncontrolledPopover
          placement="bottom"
          trigger="legacy"
          isOpen={isPopover}
          target={`add_${id}`}
          toggle={() => {
            setIsPopover(!isPopover);
          }}
        >
          <PopoverBody>
            <div>请选择任务类型：</div>
            <span
              className="span-click"
              onClick={() => {
                onHandleClick("benefits");
              }}
            >
              发放权益
            </span>
            <span
              className="span-click"
              onClick={() => {
                onHandleClick("message");
              }}
            >
              发送消息
            </span>
            {/* <span className="span-click" onClick={() => {onHandleClick('follow_up_task')}}>回访任务</span> */}
          </PopoverBody>
        </UncontrolledPopover>{" "}
      </div>
    </React.Fragment>
  );
};
export default AddTaskCom;

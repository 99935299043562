import {
  Button,
  Col,
  ConfigProvider,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import locale from "antd/locale/zh_CN";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import {
  getPlanTaskHistoriesById,
  getCustomerPlanHistory,
  getCustomerPlanTaskHistory,
} from "../../../../helpers/mallbackend_helper";
import moment from "moment";
import "../../scss/MarketingPlanHistoriesLit.scss";

const AddPlanTaskHistoryModal = (props: any) => {
  interface DataType {
    plan_customer_task_history_id: string;
    plan_history_id: string;
    plan_customer_history_id: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }
  const pageOptions: any = {
    page_size: 10, // ，每页条数
    page_number: 1, //页码
    total: 0, //总数
  };
  const pageOptions2: any = {
    page_size: 10, // ，每页条数
    page_number: 1, //页码
    total: 0, //总数
  };
  const { toggle, isOpen, currentId, planHistoryId } = props;
  const [planTaskHistoryInfo, setPlanTaskHistoryInfo] = useState<any>(null); //营销计划计划历史记录详情
  const [timeLineItems, setTimeLineItems] = useState<any[]>([]); //时间轴list
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [pagination, setPagination] = useState<any>(pageOptions); //分页当前数据项
  const [pagination2, setPagination2] = useState<any>(pageOptions2); //分页当前数据项
  const [customerList, setCustomerList] = useState([]); //营销计划历史的客户记录
  const [customerTasks, setCustomerTasks] = useState([]); //营销计划历史的客户任务记录
  const dateTime = "YYYY-MM-DD HH:mm:ss";
  const toggle2 = () => {
    setOpen2(false);
  };

  //模态框属性
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open,
    centered: true,
    autoFocus: true,
    toggle: toggle,
    className: "add-coupon-modal task-history-modal",
  };
  const modalOpts2 = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open2,
    centered: true,
    autoFocus: true,
    toggle: toggle2,
    className: "add-coupon-modal customer-task-history-modal",
  };
  const showTotal: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pagination.page_size} 条，共 ${pagination.total} 条`;
  const showTotal2: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pagination2.page_size} 条，共 ${pagination2.total} 条`;

  //根据id获取营销计划任务历史信息
  const getTaskHistories = async (id: string) => {
    const res = await getPlanTaskHistoriesById(id);
    if (res.code === 200) {
      setPlanTaskHistoryInfo(res.data);
      setTimeLineItems(res.data?.tasks);
    }
  };
  //根据计划历史id获取客户列表
  const getCustomers = async (id: string) => {
    const res = await getCustomerPlanHistory(id);
    if (res.code === 200) {
      setCustomerList(res.data);
      setPagination({ ...pagination, total: res.total });
    }
  };
  //获取指定营销计划历史的客户任务记录
  const getCustomerPlanTasks = async (customerId: string) => {
    const res = await getCustomerPlanTaskHistory(planHistoryId, customerId);
    if (res.code === 200) {
      setCustomerTasks(res.data);
      setPagination2({ ...pagination2, total: res.data?.length });
    }
  };
  const info = (id: string) => {
    setOpen2(true);
    getCustomerPlanTasks(id);
  };

  //分页更改
  const onPageChange = (pageNumber: number, page: number) => {};
  //分页更改
  const onPageChange2 = (pageNumber: number, page: number) => {};
  const columns: ColumnsType<DataType> = [
    {
      title: "客户名称",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "客户手机号",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 200,
    },
    {
      title: "是否完成",
      dataIndex: "is_completed",
      key: "is_completed",
      width: 250,
      render: (text) => {
        if (text) return "已完成";
        else return "未完成";
      },
    },
    {
      title: "执行开始时间",
      key: "actual_begin_time",
      dataIndex: "actual_begin_time",
      width: 250,
      render: (text) => {
        return text ? moment(text).format(dateTime) : "";
      },
    },
    {
      title: "执行结束时间",
      key: "actual_end_time",
      dataIndex: "actual_end_time",
      width: 250,
      render: (text) => {
        return text ? moment(text).format(dateTime) : "";
      },
    },
    {
      title: "执行耗时（毫秒）",
      dataIndex: "actual_elapsed",
      key: "actual_elapsed",
      width: 250,
    },
    {
      title: () => {
        return (
          <span>
            {"操作"}
            <Tooltip
              title={
                "当前操作行是“模板”时，则可操作“使用模板”，不是“模板”时，则可操作“查看任务执行历史记录”"
              }
            ></Tooltip>
          </span>
        );
      },
      key: "action",
      width: 100,
      render: (item) => (
        <Space size="middle">
          <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              info(item.customer_id);
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];
  const columns2: ColumnsType<DataType> = [
    {
      title: "客户名称",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "执行开始时间",
      key: "actual_begin_time",
      dataIndex: "actual_begin_time",
      width: 250,
      render: (text) => {
        return text ? moment(text).format(dateTime) : "";
      },
    },
    {
      title: "执行结束时间",
      key: "actual_end_time",
      dataIndex: "actual_end_time",
      width: 250,
      render: (text) => {
        return text ? moment(text).format(dateTime) : "";
      },
    },
    {
      title: "状态",
      dataIndex: "return_message",
      key: "return_message",
      width: 250,
    },
    {
      title: "执行耗时（毫秒）",
      dataIndex: "actual_elapsed",
      key: "actual_elapsed",
      width: 250,
    },
    {
      title: "执行失败的次数",
      dataIndex: "failed_count",
      key: "failed_count",
      width: 250,
    },
  ];
  const taskContent = (item: any) => {
    return (
      <Col md={12}>
        <div className="timeline-box">
          <div className="event-content">
            <div
              className="timeline-text"
              style={{ marginLeft: "0", marginRight: "0" }}
            >
              <h3 className="font-size-18   ">
                <strong>任务名称：</strong>
                {item.task_name}
              </h3>
              <div className="mb-0 mt-2 pt-1 text-muted">
                <strong>任务类型：</strong>
                {item?.task_type === "benefits" ? "发放权益" : null}
                {item?.task_type === "message" ? "发送消息" : null}
                {item?.task_type === "follow_up_task" ? "回访任务" : null}
                <br />
                <strong>执行内容：</strong>
                {item?.exec_content === null
                  ? "-"
                  : item?.exec_content?.tasks?.map(
                      (tasks: any, tasksIndex: number) => {
                        return (
                          <div
                            key={tasksIndex}
                            style={{
                              display: "inline-block",
                            }}
                          >
                            {tasks?.type === "point" ? (
                              <span>赠送积分</span>
                            ) : null}
                            {tasks?.type === "coupon" ? (
                              <span>赠送优惠券</span>
                            ) : null}
                            {tasks?.type === "send_sms" ? (
                              <span>发送短信</span>
                            ) : null}
                          </div>
                        );
                      }
                    )}
                <br />
                {/* <strong>
                  执行开始时间：
                  {item?.actual_begin_time !== null
                    ? moment(item?.actual_begin_time).format(dateTime)
                    : "-"}
                </strong>{" "}
                <br />
                <strong>
                  执行结束时间：
                  {item?.actual_end_time !== null
                    ? moment(item?.actual_end_time).format(dateTime)
                    : "-"}
                </strong>
                <br />
                <strong>
                  执行耗时（毫秒）：
                  {item?.actual_elapsed !== null ? item?.actual_elapsed : "-"}
                </strong>
                <br /> */}
                <strong>描述：</strong>
                {item?.description !== null ? item?.description : "-"}
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };
  useEffect(() => {
    setOpen(isOpen);
    if (isOpen && planHistoryId) {
      getTaskHistories(planHistoryId);
    }
    if (isOpen && planHistoryId) {
      getCustomers(planHistoryId);
    }
  }, [isOpen, currentId, planHistoryId]);
  return (
    <React.Fragment>
      <Modal backdrop="static" {...modalOpts} toggle={toggle}>
        <div className="modal-header">
          营销计划任务历史详情
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body ">
          <Row>
            <Col
              span={10}
              style={{
                borderRight: "1px solid #ccc",
                height: "79vh",
                overflowY: "auto",
              }}
            >
              <div className="timeline">
                <div className="timeline-container">
                  <div
                    className="timeline-launch"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <div className="timeline-box">
                      <div
                        className="timeline-text"
                        style={{ transform: "rotate(180deg)" }}
                      >
                        <h3 className="font-size-18">
                          任务名称：{planTaskHistoryInfo?.name}
                          {planTaskHistoryInfo?.is_completed ? (
                            <span
                              className="bg-success"
                              style={{
                                padding: "3px 10px",
                                color: "white",
                                borderRadius: "5px",
                                fontSize: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              已完成
                            </span>
                          ) : (
                            <span>未完成</span>
                          )}
                        </h3>
                        <p className="text-muted mb-0">
                          <br />
                          <strong>执行耗时（毫秒）：</strong>
                          <span
                            style={{
                              color: "orange",
                              fontSize: "16px",
                              marginLeft: "10px",
                              marginRight: "20px",
                            }}
                          >
                            {planTaskHistoryInfo?.actual_elapsed === null
                              ? "-"
                              : planTaskHistoryInfo?.actual_elapsed}
                          </span>
                          <strong>执行成功客户数：</strong>
                          <span
                            style={{
                              color: "orange",
                              fontSize: "16px",
                              marginLeft: "10px",
                            }}
                          >
                            {planTaskHistoryInfo?.completed_customers_count ===
                            null
                              ? "-"
                              : planTaskHistoryInfo?.completed_customers_count}
                          </span>
                          <br />
                          <strong>计划开始时间：</strong>
                          {planTaskHistoryInfo?.actual_begin_time === null
                            ? "-"
                            : moment(
                                planTaskHistoryInfo?.actual_begin_time
                              ).format(dateTime)}
                          <strong style={{ marginLeft: "10px" }}>
                            计划结束时间：
                          </strong>
                          {planTaskHistoryInfo?.actual_end_time === null
                            ? "-"
                            : moment(
                                planTaskHistoryInfo?.actual_end_time
                              ).format(dateTime)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-end">
                    <p>开始计划</p>
                  </div>
                  <div className="timeline-continue">
                    {timeLineItems?.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          {index % 2 === 0 ? (
                            <Row className="timeline-right">
                              <Col md={12}>
                                <div className="timeline-icon"></div>
                              </Col>
                              {taskContent(item)}
                            </Row>
                          ) : (
                            <Row className="timeline-left" key={index}>
                              {taskContent(item)}
                              <Col md={12} className="d-md-block d-none">
                                <div className="timeline-icon"></div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="timeline-start">
                    <p>结束计划</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <Tabs
                type="card"
                items={new Array(1).fill(null)?.map((_, i) => {
                  const id = String(i + 1);
                  return {
                    key: `Tab ${id}`,
                    label: `客户列表`,
                  };
                })}
              />
              <ConfigProvider locale={locale}>
                <Table
                  columns={columns}
                  dataSource={customerList}
                  pagination={false}
                  rowKey={(record) => record.plan_customer_history_id}
                />
                <div style={{ height: "50px", marginTop: "15px" }}>
                  <Pagination
                    showQuickJumper
                    defaultPageSize={pageOptions.sizePerPage}
                    showTotal={showTotal}
                    total={pageOptions.total}
                    onChange={onPageChange}
                    defaultCurrent={1}
                  />
                </div>
              </ConfigProvider>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-light waves-effect btn-cancel"
            onClick={toggle}
          >
            关闭
          </button>
        </div>
      </Modal>
      <Modal backdrop="static" {...modalOpts2} toggle={toggle2}>
        <div className="modal-header">
          营销计划任务历史 - 客户任务记录
          <button
            type="button"
            onClick={toggle2}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body ">
          <Tabs
            type="card"
            items={new Array(1).fill(null)?.map((_, i) => {
              const id = String(i + 1);
              return {
                key: `Tab ${id}`,
                label: `客户任务记录`,
              };
            })}
          />
          <ConfigProvider locale={locale}>
            <Table
              columns={columns2}
              dataSource={customerTasks}
              pagination={false}
              rowKey={(record) => record.plan_customer_task_history_id}
            />
            <div style={{ height: "50px", marginTop: "15px" }}>
              <Pagination
                showQuickJumper
                defaultPageSize={pageOptions2.sizePerPage}
                showTotal={showTotal2}
                total={pageOptions2.total}
                onChange={onPageChange2}
                defaultCurrent={1}
              />
            </div>
          </ConfigProvider>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-light waves-effect btn-cancel"
            onClick={toggle2}
          >
            关闭
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default AddPlanTaskHistoryModal;

import axios from "axios"
import { method } from "lodash";
import { del, get, post, put, request } from "./api_helper"
import * as url from "./mall_helper"

//用户登录
export const postMallLogin = (data: any) => request({ url: url.POST_MALL_LOGIN, data: data, method: 'post' })
//精准营销
export const getMarketingPlans = (data: any) => request({ url: url.Marketing_Plans, data: data, method: 'get' })
export const postMarketingPlans = (params: any) => request({ url: url.Marketing_Plans, data: params.data, method: 'post' })
export const deleteMarketingPlans = (id: any) => request({ url: `${url.Marketing_Plans}/${id}`, method: 'delete' })
export const getMarketingPlansById =  (id: any) => request({ url: `${url.Marketing_Plans}/${id}`, method: 'get' })
export const putMarketingPlans = (params: any) => request({ url: `${url.Marketing_Plans}/${params.id}`, data: params.data, method: 'put' })
//获取列表状态
export const getPlanStatus = (data?: any) => request({ url: url.Plans_Status, data: data, method: 'get' })
//用户特征标签
export const getUserFeaturesTags = (data: any) => request({ url: url.User_features_Tags, data: data, method: 'get' })
// 获取用户标签值
export const getDictValueTree =  (data: any) => request({ url: url.dicts, data: data, method: 'get' })
// 获取包含指定特征的客户列表
export const getFeatureCustomers = (data?:any) => request({url:url.feature_customers,data:data,method:'post'})
//获取卡券类型列表
export const getCategoryList = (data: any) => request({ url: url.GetCategoryList, data: data, method: 'get' })
//获取卡券列表
export const getBatchList =  (data: any) => request({ url: url.GetBatchList, data: data, method: 'get' })
//分页获取营销计划历史记录
export const getPlanHistories =  (data: any) => request({ url: url.GetPlanHistories, data: data, method: 'get' })
//获取计划任务历史记录列表
export const getPlanTaskHistoriesById =  (id: any) => request({ url: `${url.GetPlanHistories}/${id}`, method: 'get' })
//分页获取指定营销计划历史的客户记录
export const getCustomerPlanHistory =  (id: any,params?:any) => request({ url: `${url.GetPlanHistories}/${id}/customers`,data:params, method: 'get' })
//获取指定营销计划历史的客户任务记录
export const getCustomerPlanTaskHistory =  (id: any,customer_id:any) => request({ url: `${url.GetPlanHistories}/${id}/customers/${customer_id}`, method: 'get' })
//根据营销计划标识查询营销计划的目标客户列表
export const getCustomersByPlanId = (id:any,params:any) => request({url:`${url.Marketing_Plans}/${id}/customers`,data:params,method:'get'})


import React, { useEffect, useState } from "react";
import { Select, DatePicker, ConfigProvider, InputNumber } from "antd";
import "dayjs/locale/zh-cn";
import locale from "antd/locale/zh_CN";
import classnames from "classnames";
import { toast } from "react-toastify";
import { Col, Modal, Popover, PopoverBody, Row } from "reactstrap";
import "../../../scss/TargetPopulationCom.scss";
import {
  getUserFeaturesTags,
  getDictValueTree,
  getFeatureCustomers,
} from "../../../../../helpers/mallbackend_helper";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const TargetPopulationCom = (props: any) => {
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const rangeOption = [
    {
      label: "大于",
      value: ">",
    },
    {
      label: "小于",
      value: "<",
    },
    {
      label: "等于",
      value: "==",
    },
    {
      label: "大于等于",
      value: ">=",
    },
    {
      label: "小于等于",
      value: "<=",
    },
    {
      label: "不等于",
      value: "!=",
    },
    {
      label: "在…之内",
      value: "between",
    },
    {
      label: "在…之外",
      value: "outside",
    },
  ];
  interface CustomerTag {
    dict_type_id: String;
    name: String;
    description: String;
    render: any;
    type: String;
    value: any;
    children: [];
  }
  interface Feature {
    feature_name_id: String;
    compare_type: String;
    compare_value: String[] | String;
    feature_name: String;
  }
  const dateFormat = "YYYY/MM/DD";
  const { saveActiveTagList, marketing } = props;
  const [popoverOpen, setPopoverOpen] = useState(false); //是否显示删除询问弹框
  const [currentItemId, setCurrentItemId] = useState(""); //当前操作项
  const [activeList, setActiveList] = useState<any[]>([]); //用户选择List
  const [currentTag, setCurrentTag] = useState({});
  const [tags, setTags] = useState([]);
  const [rangeVal, setRangVal] = useState(">");
  const [activeItems, setActiveItems] = useState<string[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [featureCount, setFeatureCount] = useState(0);

  //移除当前项
  const removeCurrentItem = (e: any) => {
    let newItems: any = [];
    let featureItems:any=[];
    const attr_index: number = e.currentTarget.dataset.index;
    activeList?.map((v: any, index: number) => {
      if (index != attr_index) {
        newItems.push(v);
      }
    });
    setActiveList(newItems);
    setPopoverOpen(false);
    setCurrentItemId("");
    const items = activeItems;
    items.splice(Number(attr_index), 1);
    setActiveItems(items);
    if (items.length === 0) setFeatures([]);
    const filteredFeatures = features?.filter((e:any) => items?.includes(e.feature_name_id));
    setFeatures(filteredFeatures);
  };
  //获取用户标签树形结构
  const getUserTags = async () => {
    const params = { code: "feature_type", has_children: true, is_valid: true };
    const res = await getUserFeaturesTags(params);
    if (res.code === 200) {
      setTags(res.data[0]?.children);

      if (
        marketing?.target_customers &&
        marketing?.target_customers?.length > 0
      ) {
        getTagOptions(res.data[0]?.children, marketing?.target_customers);
      }
    }
  };
  //获取下拉框的option
  const getTagOptions = async (taglist: any, target_customers: any) => {
    const items = [];
    const data = [];
    for (const tag of target_customers) {
      items.push(tag.feature_name_id);

      const res = findCustomer(taglist, tag.feature_name_id);
      if (res) {
        let currentData = {
          dict_type_id: res.dict_type_id,
          name: res.name,
          description: res.description,
          render: res.extras.render,
          type: tag.compare_type,
          value: tag.compare_value,
          children: [],
        };
        //根据字典类型id获取字典值tree
        const result: any = await getDictValueTree({
          dict_type_id: res.dict_type_id,
        });
        if (result.code === 200) {
          if (result.data?.length > 0) {
            const option = result.data?.map((item: any) => {
              return {
                label: item.name,
                value: item.value,
              };
            });
            currentData.children = option;
            setCurrentTag(option);
          }
        }
        data.push(currentData);
      }
    }
    setActiveItems(items);
    setActiveList(data);
  };
  //点击用户标签获取对应的标签内容
  const onHandleClick = async (item: any) => {
    //判断是否重复添加
    const is_exist = activeItems.includes(item.dict_type_id);
    if (!is_exist) {
      const render = item.extras.render;
      //比较类型
      const type =
        (render.render_type === "multi_select" ||
          render.render_type === "select") &&
        render.data_type === "string"
          ? "=="
          : ">";
      setActiveItems([...activeItems, item.dict_type_id]);
      let currentData: CustomerTag = {
        dict_type_id: item.dict_type_id,
        name: item.name,
        description: item.description,
        render: render,
        type: type,
        value:
          render.data_type === "datetime" ? dayjs().format("YYYY/MM/DD") : 0,
        children: [],
      };
      //根据字典类型id获取字典值tree
      const res = await getDictValueTree({ dict_type_id: item.dict_type_id });
      if (res.code === 200) {
        if (res.data?.length > 0) {
          const option = res.data?.map((item: any) => {
            return {
              label: item.name,
              value: item.value,
            };
          });
          if (render.render_type === "multi_select") {
            currentData.value = [res.data[0].value];
          } else if (render.render_type === "select") {
            currentData.value = res.data[0].value;
          }
          currentData.children = option;
          setCurrentTag(option);
        }
      }
      setActiveList([...activeList, currentData]);
      let featureType = currentData.type;
      if (currentData?.render.render_type === "multi_select")
        featureType = "in";
      const featureData = {
        feature_name_id: currentData?.dict_type_id,
        compare_type: featureType,
        compare_value: currentData?.value,
        feature_name: currentData?.name,
      };
      const list = [...features, ...[featureData]];
      setFeatures(list);
    } else {
      toast.error("已存在，请勿重复添加！");
    }
  };
  //下拉框change
  const handleChange = (value: string[], index: number, activeItem: any) => {
    const newSelectValues = [...activeList];
    newSelectValues[index].value = value;
    setActiveList(newSelectValues);
    const updatedData = features.map((item) => {
      if (item.feature_name_id === activeItem.dict_type_id) {
        return {
          ...item,
          compare_value: value,
        };
      }
      return item;
    });
    setFeatures(updatedData);
  };
  //比较类型change
  const handleRangeChange = (value: string, index: number, type: string) => {
    let date = dayjs().format(dateFormat);
    const newSelectValues = [...activeList];
    newSelectValues[index].type = value;
    if (type === "number") {
      if (value === "between" || value === "outside") {
        newSelectValues[index].value = [0, newSelectValues[index].value];
      } else {
        if (typeof newSelectValues[index].value === "number")
          newSelectValues[index].value = newSelectValues[index].value;
        else newSelectValues[index].value = newSelectValues[index].value[0];
      }
    } else {
      if (value === "between" || value === "outside") {
        newSelectValues[index].value = [newSelectValues[index].value, date];
      } else {
        newSelectValues[index].value = newSelectValues[index].value;
      }
    }
    setActiveList(newSelectValues);
    setRangVal(value);
  };
  //时间区间change
  const handleDateTimeChange = (
    value: any,
    isRang: boolean,
    item: any,
    index: number
  ) => {
    let date: any = dayjs().format(dateFormat);
    // if (item.type === "between" || item.type === "outside") {
    //   item.value = [date,date];
    // }else{
    //   item.value = date;
    // }
    if (value) {
      if (isRang) {
        date = [value[0].format(dateFormat), value[1].format(dateFormat)];
      } else date = value.format(dateFormat);
      const newSelectValues = [...activeList];
      newSelectValues[index].value = date;
      setActiveList(newSelectValues);
    }
  };
  //数字框change
  const handelNumberChange = (
    value: any,
    type: string,
    item: any,
    index: number
  ) => {
    let val = value;
    if (item.type === "between" || item.type === "outside") {
      if (item.value) {
        if (type === "start_number") {
          val = [value, item.value[1]];
        } else {
          val = [item.value[0], value];
        }
      } else {
        if (type === "start_number") {
          val = [value, 0];
        } else {
          val = [0, value];
        }
      }
    }
    const newSelectValues = [...activeList];
    newSelectValues[index].value = val;
    setActiveList(newSelectValues);
  };
  const findCustomer = (tags: any, dict_type_id: string) => {
    if (tags) {
      for (let i = 0; i < tags?.length; i++) {
        const tag: any = tags[i];
        if (tag.dict_type_id === dict_type_id) {
          return tag;
        }
        if (tag.children && tag.children?.length > 0) {
          const result: any = findCustomer(tag.children, dict_type_id);
          if (result) {
            return result;
          }
        }
      }
    }
  };
  //查询包含指定特征的客户数量
  const getFeatureCustomersCount = async (features: any) => {
    let params = null;
    if (features.length>0) {
      params = { features };
    }
    const result = await getFeatureCustomers(params);
    if (result.code === 200) {
      setFeatureCount(result?.total);
    }
  };
  useEffect(() => {
    getFeatureCustomersCount(features);
  }, [features]);
  useEffect(() => {
    getUserTags();
  }, []);
  useEffect(() => {
    saveActiveTagList(activeList);
  }, [activeList]);
  // useEffect(() => {
  //   debugger
  //   if (
  //     marketing?.target_customers &&
  //     marketing?.target_customers?.length > 0
  //   ) {
  //     getTagOptions(marketing?.target_customers);
  //   }
  // }, [marketing?.target_customers]);
  return (
    <React.Fragment>
      <div className="modal-leftBar">
        {tags?.map((tag: any, index: number) => {
          return (
            <div key={index}>
              <div className="tagTitle">{tag.name}</div>
              <div className="tagBar">
                {tag.children?.map((item: any, itemIndex: number) => {
                  return (
                    <div
                      className={classnames(
                        "tagItem",
                        activeItems.find((e) => e === item.dict_type_id)
                          ? "activeTagItem"
                          : ""
                      )}
                      key={itemIndex}
                      id={item.dict_type_id}
                      onClick={() => {
                        onHandleClick(item);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Row>
        <Col xs="12">
          <div className="modal-rightBar mb-3">
            <Row className="row-box row-head-box">
              <div className="col-lg-8">
                创建人群
                <span style={{ color: "#8558FA" }}>同时满足</span>
                以下条件
              </div>
              <div className="col-lg-4">
                已选
                <span style={{ color: "#8558FA", padding: "0 6px" }}>
                  {" "}
                  {activeList?.length}
                </span>
                个条件，共包含
                <span style={{ color: "#8558FA", padding: "0 6px" }}>
                  {" "}
                  {featureCount}
                </span>
                个客户
              </div>
            </Row>
            <div className="modal-contentBar">
              {activeList?.map((activeItem: any, index: number) => {
                return (
                  <Row className="row-box" key={index}>
                    <div className="col-lg-2">{activeItem.name}</div>
                    <div className="col-lg-9">{activeItem.description}</div>
                    <div className="col-lg-1" style={{ textAlign: "right" }}>
                      <i
                        className="mdi mdi-close"
                        onClick={() => {
                          if (currentItemId == `Popover_${index}`) {
                            setPopoverOpen(!popoverOpen);
                            setCurrentItemId(
                              popoverOpen ? "" : `Popover_${index}`
                            );
                          } else {
                            setPopoverOpen(true);
                            setCurrentItemId(`Popover_${index}`);
                          }
                        }}
                        id={`Popover_${index}`}
                      />
                      {currentItemId == `Popover_${index}` && popoverOpen && (
                        <Popover
                          placement="top"
                          trigger="legacy"
                          target={`Popover_${index}`}
                          toggle={() => {
                            if (popoverOpen == true) setCurrentItemId("");
                            setPopoverOpen(!popoverOpen);
                          }}
                          isOpen={popoverOpen}
                          flip
                        >
                          <PopoverBody>
                            <div
                              style={{ marginBottom: "15px" }}
                              className="d-flex flex-wrap gap-2"
                            >
                              <i
                                className="bx bx-error-circle"
                                style={{
                                  color: "#faad14",
                                  fontSize: "20px",
                                }}
                              />
                              确定删除？
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <button
                                style={{ marginRight: "7px" }}
                                onClick={() => {
                                  setPopoverOpen(false);
                                  setCurrentItemId("");
                                }}
                                type="button"
                                className="btn btn-secondary btn-sm"
                              >
                                取消
                              </button>
                              <button
                                style={{ marginLeft: "7px" }}
                                data-index={index}
                                onClick={removeCurrentItem}
                                type="button"
                                className="btn btn-primary btn-sm"
                              >
                                确定
                              </button>
                            </div>
                          </PopoverBody>
                        </Popover>
                      )}
                    </div>
                    <div className="col-lg-12" style={{ height: "50px" }}>
                      {/* 下拉框 */}
                      {activeItem.render.render_type === "multi_select" ? (
                        <Select
                          key={activeItem.dict_type_id}
                          optionFilterProp="label"
                          mode="multiple"
                          allowClear
                          id={`multi_select${index}`}
                          style={{ width: "100%", marginTop: "15px" }}
                          placeholder="请选择"
                          value={activeItem.value}
                          onChange={(e) => {
                            handleChange(e, index, activeItem);
                          }}
                          options={activeItem.children}
                        ></Select>
                      ) : null}
                      {activeItem.render.render_type === "select" ? (
                        <Select
                          key={activeItem.dict_type_id}
                          optionFilterProp="label"
                          allowClear
                          style={{ width: "100%", marginTop: "15px" }}
                          placeholder="请选择"
                          value={activeItem.value}
                          onChange={(e) => {
                            handleChange(e, index, activeItem);
                          }}
                          options={activeItem.children}
                        ></Select>
                      ) : null}
                      {/* 时间选择器 */}
                      {activeItem.render.data_type === "datetime" ? (
                        <>
                          <Select
                            style={{
                              width: 120,
                              marginTop: "15px",
                              marginRight: "15px",
                            }}
                            onChange={(e) => {
                              handleRangeChange(
                                e,
                                index,
                                activeItem.render.data_type
                              );
                            }}
                            options={rangeOption}
                            value={activeItem.type}
                            placeholder="请选择"
                          />
                          {activeItem.type === "between" ||
                          activeItem.type === "outside" ? (
                            <ConfigProvider locale={locale}>
                              <RangePicker
                                format={dateFormat}
                                allowClear={false}
                                value={[
                                  dayjs(activeItem.value[0], dateFormat),
                                  dayjs(activeItem.value[1], dateFormat),
                                ]}
                                onChange={(e: any) => {
                                  handleDateTimeChange(
                                    e,
                                    true,
                                    activeItem,
                                    index
                                  );
                                }}
                              />
                            </ConfigProvider>
                          ) : (
                            <ConfigProvider locale={locale}>
                              <DatePicker
                                format={dateFormat}
                                allowClear={false}
                                value={dayjs(activeItem.value, dateFormat)}
                                onChange={(e: any) => {
                                  handleDateTimeChange(
                                    e,
                                    false,
                                    activeItem,
                                    index
                                  );
                                }}
                              />
                            </ConfigProvider>
                          )}
                        </>
                      ) : null}
                      {/* 数字框 */}
                      {activeItem.render.data_type === "number" ? (
                        <>
                          <Select
                            style={{
                              width: 120,
                              marginTop: "15px",
                              marginRight: "15px",
                            }}
                            onChange={(e) => {
                              handleRangeChange(
                                e,
                                index,
                                activeItem.render.data_type
                              );
                            }}
                            options={rangeOption}
                            value={activeItem.type}
                            placeholder="请选择"
                          />
                          <InputNumber
                            min={0}
                            style={{
                              display: "flex",
                              top: "-32px",
                              left: "130px",
                            }}
                            value={
                              activeItem.type === "between" ||
                              activeItem.type === "outside"
                                ? activeItem.value[0]
                                : activeItem.value
                            }
                            defaultValue={0}
                            onChange={(e) => {
                              handelNumberChange(
                                e,
                                "start_number",
                                activeItem,
                                index
                              );
                            }}
                          />{" "}
                          {activeItem.type === "between" ||
                          activeItem.type === "outside" ? null : (
                            <span
                              style={{
                                position: "relative",
                                top: "-58px",
                                left: "230px",
                              }}
                            >
                              {activeItem.render.unit}
                            </span>
                          )}
                          {activeItem.type === "between" ||
                          activeItem.type === "outside" ? (
                            <div
                              style={{
                                position: "relative",
                                top: "-64px",
                                left: "220px",
                              }}
                            >
                              <span
                                style={{
                                  margin: "0 10px",
                                  position: "relative",
                                  top: "4px",
                                }}
                              >
                                —
                              </span>
                              <InputNumber
                                min={0}
                                defaultValue={0}
                                value={activeItem.value[1]}
                                onChange={(e) => {
                                  handelNumberChange(
                                    e,
                                    "end_number",
                                    activeItem,
                                    index
                                  );
                                }}
                              />
                              <span
                                style={{
                                  position: "relative",
                                  top: "4px",
                                  left: "10px",
                                }}
                              >
                                {activeItem.render.unit}
                              </span>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </div>
                  </Row>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default TargetPopulationCom;

import React, { useState } from "react";

import AddTaskCom from "../Com/AddTaskCom";
// 发放权益
const IssuanceOfEquityStep = (props: any) => {
  const { activeStep, id } = props;
  let isRemove = false;
  const onHandlePlanClick = (step: string) => {
    if (!isRemove) props.onHandlePlanClick(step);
  };
  const onHandleRemove = (id: string) => {
    props.onHandleRemove(id);
  };
  return (
    <React.Fragment>
      <div
        className={[
          "plan",
          activeStep === id ? "plan-active" : "plan-default",
        ].join(" ")}
        onClick={() => {
          onHandlePlanClick(id);
        }}
      >
        <i
          className="bx bx-x"
          onClick={() => {
            isRemove = true;
            onHandleRemove(id);
          }}
        ></i>
        <span className="span-text">立即执行</span>
        <span className="span-text2">发放权益</span>
      </div>
    </React.Fragment>
  );
};
export default IssuanceOfEquityStep;

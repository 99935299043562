import React, { useEffect, useState } from "react";
import { Card, CardBody, Form, Row, Label, Col, Modal } from "reactstrap";
import TooltipCom from "../Com/TooltipCom";
import PlanTypeCom from "./PlanTypeCom";
import TargetPopulationCom from "./TargetPopulationCom";
import { Tag, Checkbox, Button, Input } from "antd";
import _ from "lodash";

// 【启动计划】
const LaunchPlan = (props: any) => {
  const { TextArea } = Input;
  const {
    setType,
    setMarketing,
    marketing,
    isDisabled,
    handleItemSave,
    pageType,
  } = props;
  const [targetPopulation, setTargetPopulation] = useState("all"); //是否为全部用户
  const [tagList, setTagList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false); //是否为模板
  const [description, setDescription] = useState("");
  const [activeList, setActiveList] = useState<any[]>([]); //用户选择List

  function tog_center() {
    // setIsOpen(false);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const saveActiveTagList = (list: any) => {
    setActiveList(list);
  };
  //选择用户标签
  const handleTagSubmit = () => {
    setIsOpen(false);
    const list: any = [];
    _.each(activeList, (tag) => {
      const value_cn = [];
      const data = {
        feature_name_id: tag.dict_type_id,
        feature_name: tag.name,
        feature_unit: tag.render?.unit,
        compare_type:
          tag.render.render_type === "multi_select" ? "in" : tag.type,
        compare_value: tag.value,
        compare_value_cn: "",
      };
      if (tag.value) {
        if (tag.render.render_type === "multi_select") {
          const arr = tag.value;
          if (typeof arr === "object") {
            for (const item of arr) {
              const res = tag.children.find(
                (e: { value: any }) => e.value === item
              );
              value_cn.push(res.label);
            }
            data.compare_value_cn = value_cn.join(",");
          }
        } else if (tag.render.render_type === "select") {
          const res = tag.children.find(
            (e: { value: any }) => e.value === tag.value
          );
          value_cn.push(res.label);
          data.compare_value_cn = value_cn.join(",");
        }
      }

      list.push(data);
    });
    setMarketing({ ["target_customers"]: list });
  };
  const onHandelClose = (index: number, e: any) => {
    const list = tagList.filter(
      (tag: any) => tag.dict_type_id !== e.dict_type_id
    );
    const tags = [];
    for (const item of list) {
      const data = {
        feature_name_id: item.dict_type_id,
        feature_name: item.name,
        feature_unit: item.render?.unit,
        compare_type: item.type,
        compare_value: item.value,
      };
      tags.push(data);
    }
    // setTagList(list);
    setMarketing({ ["target_customers"]: tags });
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    setType(value);
    if (value === "customer_behavior") {
      if (marketing.trigger_behavior_type === null) {
        setMarketing({ ["trigger_behavior_type"]: "buy_goods" });
      }
    }
    //客户行为触发 || 定期重复
    if (value === "customer_behavior" || value === "repeat") {
      if (marketing.trigger_behavior_type === null) {
        setMarketing({ ["limit_type"]: "once" });
      }
    }
  };

  useEffect(() => {
    const {
      target_customers,
      target_is_all_customer,
      is_template,
      description,
    } = marketing;
    if (target_customers != null) {
      const list = [];
      for (const item of target_customers) {
        let value = item.compare_value_cn;
        if (!value || item.compare_value_cn === ",") {
          if (typeof item.compare_value === "object") {
            value = item.compare_value.join("-");
          } else value = item.compare_value;
        }
        let currentData = {
          dict_type_id: item.feature_name_id,
          name: item.feature_name,
          unit: item.feature_unit,
          type: item.compare_type,
          value,
        };
        list.push(currentData);
      }
      setTagList(list);
    }
    setTargetPopulation(target_is_all_customer ? "all" : "appoint");
    setIsTemplate(is_template);
    setDescription(description);
  }, [
    marketing.target_customers,
    marketing.target_is_all_customer,
    marketing.is_template,
    marketing.description,
  ]);
  const params = {
    marketing: marketing,
    setMarketing: setMarketing,
    isDisabled: isDisabled,
    handleItemSave: handleItemSave,
    updateParamsData: setMarketing,
  };
  //模态框属性
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: isOpen,
    centered: true,
    autoFocus: true,
    toggle: tog_center,
    className: "target-population-Modal",
  };
  useEffect(() => {
    console.log(`****isopen=${isOpen}`);
  }),
    [isOpen];
  return (
    <React.Fragment>
      <Card className="form-card">
        <CardBody style={{ padding: "0 !important" }}>
          <Form>
            <Row>
              <Label
                htmlFor="horizontal-firstname-input"
                className="col-sm-3 col-form-label"
              >
                <span style={{ color: "red" }}>* </span> 计划类型：
              </Label>
              <Col>
                <div className="form-check mb-3" style={{ marginLeft: "20px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="planType"
                    id="formRadios1"
                    value="once"
                    disabled={isDisabled}
                    checked={marketing.type === "once"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="formRadios1">
                    单次营销
                  </label>
                  <TooltipCom type="1" tooltipTopId="tooltipTop1"></TooltipCom>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="planType"
                    id="formRadios2"
                    value="customer_behavior"
                    disabled={isDisabled}
                    checked={marketing.type === "customer_behavior"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="formRadios2">
                    客户行为触发
                  </label>
                  <TooltipCom type="2" tooltipTopId="tooltipTop2"></TooltipCom>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="planType"
                    id="formRadios3"
                    value="repeat"
                    disabled={isDisabled}
                    checked={marketing.type === "repeat"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label className="form-check-label" htmlFor="formRadios3">
                    定期重复
                  </label>
                  <TooltipCom type="3" tooltipTopId="tooltipTop3"></TooltipCom>
                </div>
                {/* 计划类型内容 */}
                <PlanTypeCom {...params}></PlanTypeCom>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Label
                htmlFor="horizontal-email-input"
                className="col-sm-3 col-form-label"
              >
                <span style={{ color: "red" }}>* </span>
                目标人群：
              </Label>
              <Col>
                <div className="form-check mb-3" style={{ marginLeft: "20px" }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="targetPopulation"
                    id="targetPopulation"
                    value="all"
                    disabled={isDisabled}
                    checked={targetPopulation === "all"}
                    onChange={() => {
                      setTargetPopulation("all");
                      setMarketing({ ["target_is_all_customer"]: true });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="targetPopulation"
                  >
                    全部用户
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="targetPopulation"
                    id="targetPopulation2"
                    value="appoint"
                    disabled={isDisabled}
                    checked={targetPopulation === "appoint"}
                    onChange={() => {
                      setTargetPopulation("appoint");
                      setMarketing({
                        ["target_is_all_customer"]: false,
                      });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="targetPopulation2"
                  >
                    指定用户
                  </label>
                </div>
                {/* 目标人群 */}

                {targetPopulation === "appoint" ? (
                  <>
                    <div
                      style={{
                        background: "#f2f3f5",
                        paddingTop: "10px",
                        marginLeft: "18px",
                        width: "85%",
                        borderRadius: "5px",
                      }}
                    >
                      <Row>
                        <Col>
                          <Button
                            className="btn btn-outline-light waves-effect"
                            style={{
                              background: "white",
                              marginLeft: "20px",
                            }}
                            disabled={isDisabled}
                            onClick={() => {
                              setIsOpen(true);
                            }}
                          >
                            选择指定用户
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ margin: "20px 10px" }}>
                        <span>已选条件：</span>
                        <div
                          style={{
                            padding: " 5px 10px",
                            top: "-32px",
                            left: "80px",
                            width: "95%",
                            wordWrap: "break-word",
                            display: "inline-block",
                          }}
                        >
                          {tagList?.map((item: any, index: number) => {
                            return (
                              <Tag
                                key={item.dict_type_id}
                                closable
                                style={{ margin: "5px 5px 5px 0" }}
                                onClose={(e: any) => {
                                  onHandelClose(index, item);
                                }}
                              >
                                {item.name}
                                {item.type === ">" ? "大于" : ""}
                                {item.type === "<" ? "小于" : ""}
                                {item.type === ">=" ? "大于等于" : ""}
                                {item.type === "<=" ? "小于等于" : ""}
                                {item.type === "==" ? "等于" : ""}
                                {item.type === "!=" ? "不等于" : ""}
                                {item.type === "in" ? "包含" : ""}
                                {item.type === "within" ? "在…之内" : ""}
                                {item.type === "beyond" ? "在…之外" : ""}：
                                {item.value}
                                {item?.unit}
                              </Tag>
                            );
                          })}
                        </div>
                      </Row>
                    </div>
                  </>
                ) : null}
              </Col>
            </Row>

            {pageType !== "copy_template" ? (
              <Row style={{ marginTop: "10px" }}>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label"
                >
                  是否为模板：
                </Label>
                <Col>
                  <div className="form-check mb-3">
                    <Checkbox
                      checked={isTemplate}
                      disabled={isDisabled}
                      onChange={() => {
                        setIsTemplate(!isTemplate);
                        setMarketing({ ["is_template"]: !isTemplate });
                      }}
                    ></Checkbox>
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row style={{ marginTop: "10px" }}>
              <Label
                htmlFor="horizontal-firstname-input"
                className="col-sm-3 col-form-label"
              >
                <span style={{ color: "red" }}></span> 描述：
              </Label>
              <Col>
                <div className="form-check mb-3">
                  <TextArea
                    value={description}
                    disabled={isDisabled}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setMarketing({ ["description"]: e.target.value });
                    }}
                    rows={3}
                    style={{ width: "600px" }}
                  />
                </div>
              </Col>
            </Row>
          </Form>

          <Modal
            backdrop="static"
            {...modalOpts}
            toggle={() => {
              tog_center();
            }}
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">筛选指定用户</h5>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <TargetPopulationCom
                marketing={marketing}
                saveActiveTagList={saveActiveTagList}
              ></TargetPopulationCom>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-light waves-effect btn-cancel"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                取消
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect btn-save"
                onClick={handleTagSubmit}
              >
                保存
              </button>
            </div>
          </Modal>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default LaunchPlan;

/*
 * @Author: afxucamd afxucamd@qq.com
 * @Date: 2023-06-19 13:43:41
 * @LastEditors: afxucamd afxucamd@qq.com
 * @LastEditTime: 2023-06-19 13:44:48
 * @FilePath: /marketing-mp/src/public-path.js
 * @Description: 修改运行时的publicPath
 */
if (window.__POWERED_BY_QIANKUN__) {
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}
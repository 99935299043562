import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipCom = (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const tipsData = [
    {
      id: "1",
      values: "特定时间触发，只执行一次。",
    },
    {
      id: "2",
      values: "当用户每次完成某个行为后执行计划，多次执行。",
    },
    {
      id: "3",
      values: "特定周期时间触发，多次执行。",
    },
    {
      id: "4",
      values:
        "客户支付产品、服务、卡项时触发，包括客户在网店下单和到店开单收银。",
    },
    {
      id: "5",
      values: "到店开单服务和网店购买服务后到店核销时触发。",
    },
    {
      id: "6",
      values: "客户在支付时使用卡项，包括次卡、充值卡、折扣卡。",
    },
    {
      id: "7",
      values:
        "同一个客户即使多次满足触发条件，也只能参与1次。比如针对新客收单优惠，防止用户薅羊毛。",
    },
    {
      id: "8",
      values:
        "同一个客户只要满足触发条件，可以不限次参与。比如周三会员日，同一个会员在每个周三都能参与。",
    },
    {
      id: "9",
      values:
        "同一个客户在固定时间内即使多次满足触发条件，也只能参与1次。比如服务回访，客户即使在1天内完成了多个服务项目，也只需要进行一次客户回访。",
    },
    {
      id: "10",
      values:
        "若有多个营销顾问，则随机选择一个营销顾问作为跟进人。如没有营销顾问，则不生成回访任务。",
    },
    {
      id: "11",
      values: "仅适用于产生消费的 “客户行为触发” 类计划。",
    },
    {
      id: "12",
      values: "选择“所有商场”，后续门店新增所有服务将自动更新选中。",
    },
    {
      id: "13",
      values: "选择“所有商品分类”，后续门店新增所有产品将自动更新选中。",
    },
    {
      id: "14",
      values: "选择“所有商品”，后续门店新增所有折扣卡将自动更新选中。",
    },
    {
      id: "15",
      values:
        "选择“所有服务”，后续门店新增所有次卡将自动更新选中，包括定制卡。",
    },
    {
      id: "16",
      values: "选择“所有充值卡”，后续门店新增所有充值卡将自动更新选中",
    },
  ];
  const { type, tooltipTopId } = props;
  const tips = tipsData.filter((e) => e.id === type)[0].values;
  return (
    <React.Fragment>
      <>
        <i
          className="bx dripicons-question"
          id={tooltipTopId}
        ></i>
        <Tooltip
          placement="right"
          isOpen={isShow}
          target={tooltipTopId}
          toggle={() => {
            setIsShow(!isShow);
          }}
          style={{
            textAlign: "left",
            background: "white",
            color: "black",
            border: "1px solid #d5dbe0",
            marginBottom: "10px",
          }}
        >
          {tips}
        </Tooltip>
      </>
    </React.Fragment>
  );
};
export default TooltipCom;

import React from "react";
import ReactTag from "react-meta-tags";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DashedLine from "src/pages/AllCharts/apex/dashedLine";
import LineColumnArea from "../../AllCharts/apex/LineColumnArea";
import SplineArea from "../../AllCharts/apex/SplineArea";
import PieChart from "../../AllCharts/apex/PieChart";

const clusterAnalysis = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <ReactTag>
          <title>分群分析</title>
        </ReactTag>
        <Container fluid className="clusterAnalysis-container">
            <Row >
                <Card>
                    <CardBody>
                    <h3>分群分析</h3>
                    </CardBody>
                </Card>
            </Row>
          <Row style={{marginTop:'20px'}}>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <SplineArea />
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
          <Col xl={6} style={{marginTop:'15px'}}>
              <Card style={{height:'100%'}}>
                <CardBody>
                  <PieChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} style={{marginTop:'15px'}}>
              <Card>
                <CardBody>
                  <DashedLine />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default clusterAnalysis;

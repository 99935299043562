import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import "dayjs/locale/zh-cn";
import locale from "antd/locale/zh_CN";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  ConfigProvider,
  Dropdown,
  MenuProps,
  Pagination,
  PaginationProps,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { getCustomersByPlanId } from "src/helpers/mallbackend_helper";
import DisplayCustomersList from "src/components/DisplayCustomersList";

const DataTableCom = (props: any) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    marketingList,
    total,
    getMarketing,
    pageNumber,
    pageSize,
    marketingDel,
    updateMarketingInfo,
  } = props;
  // debugger
  const pageOptions: any = {
    sizePerPage: pageSize, // ，每页条数
    page: pageNumber, //页码
    totalSize: total, //总数
    custom: true,
  };
  //页面state初始化

  const [isShow, setIsShow] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(""); //当前操作项
  const [showCustomer, setShowCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [planId, setPlanId] = useState("");
  //目标客户列表分页
  const [customersTotal,setCustomersTotal] = useState(0);
  const [customerSize,setCustomerSize] = useState(5);
  const [customerNumber,setCustomerNumber] = useState(1);

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `每页 ${pageOptions.sizePerPage} 条，共 ${total} 条`;

  //删除
  const handleDel = async () => {
    setPopoverOpen(false);
    marketingDel(currentItemId);
  };
  //分页更改
  const onPageChange = (sizePerPage: number, page: number) => {
    getMarketing(sizePerPage, page);
  };
  //详情、修改、复制
  const updateMarketing = (id: string, type: string) => {
    updateMarketingInfo(id, type);
    };
    //获取目标客户列表
    const getCustomers = async (id: string,data?:any) => {
      let pid = id;
      if (!pid) pid = planId;
      const params = {
        pageSize:data?.pageSize || customerSize,
        pageNumber:data?.pageNumber || customerNumber,
        keyword:data?.keyword,
      };
      const result = await getCustomersByPlanId(pid,params);
      if (result.code === 200) {
        setCustomers(result.data);
        setShowCustomer(true);
        setCustomersTotal(result.total);
      }
    };
    const customerProps = {
      isOpen: showCustomer,
      toggle: ()=>{setShowCustomer(false)},
      data: customers,
      planId,
      customerSize,
      customerNumber,
      getList: getCustomers,
      dataTotal:customersTotal,
    };
  interface DataType {
    plan_id: string;
    name: string;
    type_cn: string;
    trigger_time: string;
    target_is_all_customer_cn: string;
    status_cn: string;
    created_at: string;
  }

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: "复制",
    },
    {
      key: 2,
      disabled: isDisabled,
      label: "使用模板",
    },
    {
      key: 3,
      disabled: !isDisabled,
      label: "查看任务执行历史记录",
    },
  ];
  const handleMenuClick = (item: any, e: any) => {
    if (e.key === "1") {
      updateMarketing(item.plan_id, "copy");
    } else if (e.key === "2") {
      updateMarketing(item.plan_id, "copy_template");
    } else {
      //跳转查看历史
      history.push({
        pathname: `/marketingPlanHistoriesList`,
        state: { plan_id: item.plan_id, name: item.name },
      });
    }
  };
  const dateTime = "YYYY-MM-DD HH:mm:ss";
  const columns: ColumnsType<DataType> = [
    {
      title: "活动名称",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "计划类型",
      dataIndex: "type_cn",
      key: "type_cn",
      width: 200,
    },
    {
      title: "活动时间",
      dataIndex: "trigger_time",
      key: "trigger_time",
      width: 250,
      render: (time, item: any) => {
        if (item?.plan_is_forever) {
          return "长期有效";
        } else if (item?.effective_time && item?.expire_time) {
          return `${moment(item?.effective_time).format(dateTime)} - ${moment(
            item?.expire_time
          ).format(dateTime)}`;
        } else {
          return "-";
        }
      },
    },
    {
      title: "覆盖客户",
      key: "target_is_all_customer_cn",
      dataIndex: "target_is_all_customer_cn",
      width: 250,
      render: (e, item: any) => {
        return (
          <>
            <span
              style={{ color: "#4576F9" }}
              onClick={() => {
                getCustomers(item.plan_id);
                setPlanId(item.plan_id);
              }}
            >
              {e}
            </span>
          </>
        );
      },
    },
    {
      title: "是否为模板",
      dataIndex: "is_template",
      key: "is_template",
      width: 200,
      render: (status) => {
        if (status === true) {
          return <span> 是 </span>;
        } else {
          return <span> 否 </span>;
        }
      },
    },
    {
      title: "状态",
      dataIndex: "status_cn",
      key: "status_cn",
      width: 200,
      render: (status, item: any) => {
        if (status === "-") {
          return <span> {status} </span>;
        }
        if (item?.status === "finished") {
          return <span className="bg-danger status-style">{status}</span>;
        } else if (item?.status === "draft") {
          return <span className="bg-soft-dark status-style">{status}</span>;
        } else if (item?.status === "started") {
          return <span className="bg-success status-style">{status}</span>;
        } else if (item?.status === "paused") {
          return <span className="bg-warning status-style">{status}</span>;
        }
      },
    },
    {
      title: "更新时间",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: () => {
        return (
          <span>
            {"操作"}
            <Tooltip
              title={
                "当前操作行是“模板”时，则可操作“使用模板”，不是“模板”时，则可操作“查看任务执行历史记录”"
              }
            >
              <QuestionCircleOutlined
                style={{ marginLeft: "5px", color: "rgb(123 122 122)" }}
              />
            </Tooltip>
          </span>
        );
      },
      key: "action",
      width: 100,
      render: (item) => (
        <Space size="middle">
          <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              updateMarketing(item.plan_id, "info");
            }}
          >
            详情
          </Button>
          |
          {item?.type === "once" && item?.status === "finished" ? (
            <Tooltip
              placement="topLeft"
              title="类型为‘单次营销’且状态为‘已结束’的任务不可再编辑!"
            >
              <Button
                disabled={item?.type === "once" && item?.status === "finished"}
                style={{ width: "40px", margin: "0 0px 0 -20px" }}
                type="link"
              >
                编辑
              </Button>
            </Tooltip>
          ) : (
            <Button
              id={item.plan_id}
              disabled={item?.type === "once" && item?.status === "finished"}
              style={{ width: "40px", margin: "0 0px 0 -20px" }}
              type="link"
              onClick={(e) => {
                updateMarketing(item.plan_id, "edit");
              }}
            >
              编辑
            </Button>
          )}
          |
          {/* <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              updateMarketing(item.plan_id, "copy");
            }}
          >
            复制
          </Button>
          | */}
          <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              setPopoverOpen(true);
              setCurrentItemId(item.plan_id);
            }}
          >
            删除
          </Button>
          |
          <Dropdown
            placement="bottomLeft"
            onOpenChange={() => {
              setIsDisabled(!item.is_template);
            }}
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(item, e);
              },
            }}
          >
            <Button
              type="link"
              style={{ width: "40px", margin: "0 0px 0 -20px" }}
            >
              …
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    pageOptions.totalSize = total;
    pageOptions.page = pageNumber;
  }, [total, pageNumber]);
  return (
    <React.Fragment>
      {popoverOpen && (
        <SweetAlert
          title="确定要删除?"
          showCancel
          confirmBtnText="确定"
          cancelBtnText="取消"
          cancelBtnBsStyle="soft-dark"
          onCancel={() => {
            setPopoverOpen(false);
          }}
          onConfirm={() => {
            handleDel();
          }}
        />
      )}
      <div className="container-fluid dataTableCom">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ConfigProvider locale={locale}>
                  <Table
                    columns={columns}
                    dataSource={marketingList}
                    rowKey={(record) => record.plan_id}
                    pagination={false}
                  />

                  <DisplayCustomersList {...customerProps} />
                  <Pagination
                    showQuickJumper
                    defaultPageSize={pageOptions.sizePerPage}
                    showTotal={showTotal}
                    total={pageOptions.totalSize}
                    onChange={onPageChange}
                    defaultCurrent={1}
                  />
                </ConfigProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DataTableCom;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Popover,
  PopoverBody,
  Row,
} from "reactstrap";
import TooltipCom from "../Com/TooltipCom";
import AddItemModal from "./AddItemModal";
import { Button, ConfigProvider, DatePicker } from "antd";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";
import locale from "antd/locale/zh_CN";

const PlanTypeCom = (props: any) => {
  const { RangePicker } = DatePicker;
  const {
    setMarketing,
    marketing,
    isDisabled,
    handleItemSave,
    updateParamsData,
  } = props;
  const planType = marketing?.type;
  const units = [
    {
      index: 1,
      label: "天",
      value: "day",
    },
    {
      index: 2,
      label: "周",
      value: "week",
    },
    {
      index: 3,
      label: "月",
      value: "month",
    },
    {
      index: 4,
      label: "年",
      value: "year",
    },
  ];
  const dateFormat = "MM-DD";
  const dateArrFormat = "YYYY-MM-DD";
  const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
  //重复周期配置
  const repetitionPeriodOption = [
    {
      index: 1,
      label: "每天",
      value: "daily",
    },
    {
      index: 2,
      label: "每周",
      value: "weekly",
    },
    {
      index: 3,
      label: "每月",
      value: "monthly",
    },
    {
      index: 4,
      label: "每年",
      value: "yearly",
    },
  ];

  const weeklyOption = [
    {
      index: 1,
      label: "周一",
      value: 1,
    },
    {
      index: 2,
      label: "周二",
      value: 2,
    },
    {
      index: 3,
      label: "周三",
      value: 3,
    },
    {
      index: 4,
      label: "周四",
      value: 4,
    },
    {
      index: 5,
      label: "周五",
      value: 5,
    },
    {
      index: 6,
      label: "周六",
      value: 6,
    },
    {
      index: 7,
      label: "周天",
      value: 0,
    },
  ];
  const [addItemOpen, setAddItemOPen] = useState(false); //添加商品弹窗是否显示
  const [itemList, setItemList] = useState<any>([]); //添加的商品列表
  const [currentItemId, setCurrentItemId] = useState(""); //当前操作项
  const [popoverOpen, setPopoverOpen] = useState(false); //是否显示删除询问弹框
  const { trigger_time } = marketing;
  let dateValue = new Date().toISOString().slice(0, 10).toString(); //日期 YYYY-MM-DD
  let timeValue = new Date().toLocaleTimeString().slice(0, 5).toString(); //时间 HH:mm
  if (trigger_time) {
    dateValue = trigger_time?.slice(0, 10);
    timeValue = trigger_time?.slice(11, 16);
  }
  const [date, setDate] = useState(dateValue); //获取系统当前日期
  const [time, setTime] = useState(timeValue); //获取系统当前时间
  const [isLongEffective, setIsLongEffective] = useState("forever"); //是否长期有效

  const [limitType, setLimitType] = useState("once"); //参与限制
  const [limitCount, setLimitCount] = useState("1"); //参与次数的计数周期
  const [limitUnit, setLimitUnit] = useState("天"); //参与限制的计数周期单位

  const [periodUnit, setPeriodUnit] = useState("daily");
  const [daily, setDaily] = useState(
    new Date().toLocaleTimeString().slice(0, 5).toString()
  );
  const [weekly, setWeekly] = useState("1");
  const [monthly, setMonthly] = useState("1");
  const [yearly, setYearly] = useState(dayjs().format(dateFormat));

  const [triggerDateTime, setTriggerDateTime] = useState(
    dayjs().format(dateTimeFormat)
  ); //触发时间
  const [foreverDateArr, setForeverDateArr] = useState([
    dayjs().day(-30),
    dayjs(),
  ]);

  const [monthlyOption, setMonthlyOption] = useState(() => {
    let options: any = [];
    for (let i = 1; i <= 31; i++) {
      options.push(
        <option key={i} value={i}>
          {i}日
        </option>
      );
    }
    return options;
  });

  //保存
  const handleModalSave = (e: any) => {
    setItemList(e);
    handleItemSave(e);
  };
  const addItemModalProps = {
    marketing: marketing,
    isOpen: addItemOpen,
    onOk: handleModalSave,
    toggle: () => {
      setAddItemOPen(!addItemOpen);
    },
  };

  // 触发时间Change
  const handelDateTimeChange = (e: any) => {
    const dateTime = dayjs(e).format();
    setMarketing({ ["trigger_time"]: dateTime });
  };
  //触发行为change
  const onHandleBehaviorChange = (e: any) => {
    setMarketing({ ["trigger_behavior_type"]: e.target.value });
  };

  //是否长期有效
  const onHandleIsForeverChange = (e: any) => {
    let isForever = false;
    if (e.target.value === "forever"){
      isForever = true;
      handelForeverDate(foreverDateArr);
    } 
    setMarketing({ ["plan_is_forever"]: isForever });
  };

  //计划生效时间
  const handelForeverDate = (e: any) => {
    const effective_time = dayjs(e[0]).format();
    const expire_time = dayjs(e[1]).format();
    setMarketing({ ["effective_time"]: effective_time });
    setMarketing({ ["expire_time"]: expire_time });
  };
  //参与限制
  const onHandleLimitChange = (e: any) => {
    setMarketing({ ["limit_type"]: e.target.value });
  };
  //移除当前项
  const removeCurrentItem = (value: any) => {
    let newItems: any = [];
    debugger;
    const ruleArr: any = [];
    itemList?.map((v: any) => {
      if (v.value != value) {
        newItems.push(v);

        const items = {
          type: v.type,
          is_match_all: true,
          items: [],
        };
        ruleArr.push(items);
      }
    });

    setItemList(newItems);
    updateParamsData({ ["trigger_rules"]: { rules: ruleArr } });
    setPopoverOpen(false);
    setCurrentItemId("");
  };
  const getTrigger_timeTime = () => {
    if (planType === "once") {
      const second = time?.length === 5 ? ":00" : "";
      const dateTime = date + " " + time + second;
      setMarketing({ ["trigger_time"]: dateTime });
    } else if (planType === "repeat") {
      let rule = `${daily}`;
      if (rule === "undefined") return;
      // let year = yearly.slice(5, 10);
      switch (periodUnit) {
        case "daily":
          rule = `${daily}`;
          break;
        case "weekly":
          rule = `${weekly}/${daily}`;
          break;
        case "monthly":
          rule = `${monthly}/${daily}`;
          break;
        case "yearly":
          rule = `${yearly}/${daily}`;
          break;
      }
      setMarketing({ ["trigger_time"]: rule });
    }
  };
  useEffect(() => {
    getTrigger_timeTime();
  }, [periodUnit, daily, weekly, monthly, yearly]);
  useEffect(() => {
    const {
      type,
      trigger_time,
      effective_time,
      expire_time,
      limit_count_period,
      limit_count_period_unit,
      plan_is_forever,
      limit_type,
    } = marketing;
    if (type === "once" && trigger_time) {
      if (trigger_time?.length < 19) {
        setTriggerDateTime(dayjs().format(dateTimeFormat));
        setMarketing({ ["trigger_time"]: dayjs().format(dateTimeFormat) });
      } else setTriggerDateTime(trigger_time);
    }
    if (type === "customer_behavior" || type === "repeat") {
      const isForever = plan_is_forever ? "forever" : "delay";
      setIsLongEffective(isForever);
      if (!plan_is_forever && effective_time && expire_time) {
        const foreverDate: any = [
          dayjs(effective_time).format(dateArrFormat),
          dayjs(expire_time).format(dateArrFormat),
        ];
        setForeverDateArr(foreverDate);
      }
      setLimitType(limit_type);
    }
    // if (type === "customer_behavior" || type === "repeat") {

    // }
    if (limit_count_period && limit_count_period_unit) {
      setLimitCount(limit_count_period);
      setLimitUnit(limit_count_period_unit);
    }
  }, [marketing]);
  useEffect(() => {
    const rules = marketing?.trigger_rules?.rules;
    if (rules && rules?.length > 0) {
      const list = [];
      for (const item of rules) {
        if (item.is_match_all) {
          const arr = {
            name: "所有商场",
            value: "商场",
            type: "mall",
            is_match_all: true,
          }; //mall: 商场，goods_category: 商品分类, goods: 商品，service: 服务
          if (item.type === "goods_category") {
            arr.name = "所有商品分类";
            arr.value = "商品分类";
            arr.type = "goods_category";
          } else if (item.type === "goods") {
            arr.name = "所有商品";
            arr.value = "商品";
            arr.type = "goods";
          } else if (item.type === "service") {
            arr.name = "所有服务";
            arr.value = "服务";
            arr.type = "service";
          }
          list.push(arr);
        }
      }
      setItemList(list);
    }
  }, [marketing?.trigger_rules?.rules, marketing?.trigger_frequency]);
  useEffect(() => {
    const unit = marketing?.trigger_frequency;
    if (unit) {
      setPeriodUnit(unit);
      const dateArr = marketing?.trigger_time?.split("/");
      let time = "";
      if (unit === "daily" || dateArr?.length === 1) {
        time =
          dateArr[0]?.length !== 5
            ? new Date().toLocaleTimeString().slice(0, 5).toString()
            : dateArr[0];
      } else {
        time = dateArr[1];
      }
      setDaily(time);
      if (dateArr?.length > 1) {
        if (unit === "weekly") {
          if (dateArr[0]?.length > 1) setWeekly("1");
          else setWeekly(dateArr[0]);
        } else if (unit === "monthly") {
          if (dateArr[0]?.length > 1) setMonthly("1");
          else setMonthly(dateArr[0]);
        } else if (unit === "yearly") {
          setYearly(dateArr[0]);
        }
      }
    }
  }, [marketing?.trigger_frequency]);
  return (
    <React.Fragment>
      <Card className="single-marketing-card" style={{ marginLeft: "20px" }}>
        <CardBody style={{ padding: "0 !important" }}>
          {planType === "once" ? (
            <Row>
              <Label
                htmlFor="horizontal-firstname-input"
                className="col-sm-3 col-form-label trigger-date-label"
              >
                触发时间：
              </Label>
              <Col>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    className="triggerDateTime"
                    value={dayjs(triggerDateTime)}
                    disabled={isDisabled}
                    format={dateTimeFormat}
                    showTime
                    onOk={handelDateTimeChange}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          ) : null}

          {planType === "repeat" ? (
            <div className="repeat-regularly-card">
              <Row>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label repeat-date-label"
                >
                  重复时间：
                </Label>
                <Col>
                  <select
                    className="form-select"
                    value={periodUnit}
                    disabled={isDisabled}
                    onChange={(e) => {
                      setPeriodUnit(e.target.value);
                      setMarketing({ ["trigger_frequency"]: e.target.value });
                    }}
                  >
                    {repetitionPeriodOption?.map((v, i) => {
                      return (
                        <option value={v.value} key={i}>
                          {v.label}
                        </option>
                      );
                    })}
                  </select>
                  <Input
                    className="form-control repeat-time"
                    type="time"
                    id="example-time-input"
                    name="repeatTime"
                    disabled={isDisabled}
                    style={
                      periodUnit !== "daily" ? { marginLeft: "245px" } : {}
                    }
                    value={daily}
                    onChange={(e) => {
                      setDaily(e.target.value);
                    }}
                  />
                  {periodUnit === "weekly" && (
                    <select
                      style={{
                        marginLeft: "85px",
                        position: "absolute",
                        top: "0",
                        width: "150px",
                      }}
                      className="form-select"
                      disabled={isDisabled}
                      value={weekly}
                      onChange={(e) => {
                        setWeekly(e.target.value);
                      }}
                    >
                      {weeklyOption?.map((v, i) => {
                        return (
                          <option value={v.value} key={i}>
                            {v.label}
                          </option>
                        );
                      })}
                    </select>
                  )}{" "}
                  {periodUnit === "monthly" && (
                    <select
                      className="form-select"
                      style={{
                        marginLeft: "85px",
                        position: "absolute",
                        top: "0",
                        width: "150px",
                      }}
                      disabled={isDisabled}
                      value={monthly}
                      onChange={(e) => {
                        setMonthly(e.target.value);
                        // handleMonthlyChange
                      }}
                    >
                      {monthlyOption}
                    </select>
                  )}{" "}
                  {periodUnit === "yearly" && (
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        className="form-control  start-date"
                        disabled={isDisabled}
                        allowClear={false}
                        format={dateFormat}
                        style={{
                          width: "150px",
                          marginLeft: "85px",
                          height: "30px",
                          position: "absolute",
                          top: "0",
                        }}
                        value={dayjs(yearly)}
                        onChange={(e: any) => {
                          const date = dayjs(e)
                            .startOf("day")
                            .format(dateFormat);
                          setYearly(date);
                          // handleYearlyChange
                        }}
                      />
                    </ConfigProvider>
                  )}
                </Col>
              </Row>
            </div>
          ) : null}
          {planType === "customer_behavior" ? (
            <div className="customer-behavior-trigger-card">
              <Row style={{ marginTop: "10px" }}>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label behavior-trigger-label"
                >
                  触发行为：
                </Label>
                <Col>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="triggerBehavior"
                      id="triggerBehaviorRadios1"
                      value="buy_goods"
                      disabled={isDisabled}
                      checked={marketing?.trigger_behavior_type === "buy_goods"}
                      onChange={onHandleBehaviorChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="triggerBehaviorRadios1"
                    >
                      购买商品
                    </label>
                    <TooltipCom
                      type="4"
                      tooltipTopId="tooltipTop4"
                    ></TooltipCom>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="triggerBehavior"
                      id="triggerBehaviorRadios2"
                      value="service_completed"
                      disabled={isDisabled}
                      checked={
                        marketing?.trigger_behavior_type === "service_completed"
                      }
                      onChange={onHandleBehaviorChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="triggerBehaviorRadios2"
                    >
                      服务完成
                    </label>
                    <TooltipCom
                      type="5"
                      tooltipTopId="tooltipTop5"
                    ></TooltipCom>
                  </div>
                  {/* <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="triggerBehavior"
                      id="triggerBehaviorRadios3"
                      value="swipe_card"
                      disabled={isDisabled}
                      checked={marketing?.trigger_behavior_type === "swipe_card"}
                      onChange={onHandleBehaviorChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="triggerBehaviorRadios3"
                    >
                      划卡
                    </label>
                    <TooltipCom
                      type="6"
                      tooltipTopId="tooltipTop6"
                    ></TooltipCom>
                  </div> */}
                </Col>
              </Row>
              <Row style={{ marginTop: "15px" }}>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label behavior-trigger-label"
                >
                  选择商品：
                </Label>
                <Col>
                  <Button
                    type="link"
                    className={isDisabled ? "" : "addCommodity"}
                    style={{ marginLeft: "-17px", marginTop: "-6px" }}
                    disabled={isDisabled}
                    onClick={() => {
                      setAddItemOPen(true);
                    }}
                  >
                    添加
                  </Button>
                </Col>
              </Row>
              {itemList?.length > 0 ? (
                <>
                  <Row className="row-box tab-header">
                    <div className="col-lg-4">商品（{itemList?.length}）</div>
                    <div className="col-lg-4">类型</div>
                    <div className="col-lg-4">操作</div>
                  </Row>
                  <div className="div-tab">
                    {itemList?.map((item: any, index: number) => {
                      return (
                        <Row className="row-box tab-content" key={index}>
                          <div className="col-lg-4">
                            <i className="bx bxs-folder-open"></i> {item.name}
                          </div>
                          <div className="col-lg-4">{item.value}</div>
                          <div className="col-lg-4">
                            {" "}
                            <Button
                              className={isDisabled ? "" : "addCommodity"}
                              type="link"
                              disabled={isDisabled}
                              id={`items_${index}`}
                              onClick={() => {
                                if (currentItemId == `items_${index}`) {
                                  setPopoverOpen(!popoverOpen);
                                  setCurrentItemId(
                                    popoverOpen ? "" : `items_${index}`
                                  );
                                } else {
                                  setPopoverOpen(true);
                                  setCurrentItemId(`items_${index}`);
                                }
                              }}
                            >
                              删除
                            </Button>
                            {currentItemId == `items_${index}` &&
                              popoverOpen && (
                                <Popover
                                  placement="top"
                                  trigger="legacy"
                                  target={`items_${index}`}
                                  toggle={() => {
                                    if (popoverOpen == true)
                                      setCurrentItemId("");
                                    setPopoverOpen(!popoverOpen);
                                  }}
                                  isOpen={popoverOpen}
                                  flip
                                >
                                  <PopoverBody>
                                    <div
                                      style={{ marginBottom: "15px" }}
                                      className="d-flex flex-wrap gap-2"
                                    >
                                      <i
                                        className="bx bx-error-circle"
                                        style={{
                                          color: "#faad14",
                                          fontSize: "20px",
                                        }}
                                      />
                                      确定删除？
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <button
                                        style={{ marginRight: "7px" }}
                                        onClick={() => {
                                          setPopoverOpen(false);
                                          setCurrentItemId("");
                                        }}
                                        type="button"
                                        className="btn btn-secondary btn-sm"
                                      >
                                        取消
                                      </button>
                                      <button
                                        style={{ marginLeft: "7px" }}
                                        onClick={() => {
                                          removeCurrentItem(item.value);
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                      >
                                        确定
                                      </button>
                                    </div>
                                  </PopoverBody>
                                </Popover>
                              )}
                          </div>
                        </Row>
                      );
                    })}
                  </div>
                </>
              ) : null}

              {addItemOpen ? (
                <AddItemModal {...addItemModalProps}></AddItemModal>
              ) : null}
            </div>
          ) : null}
          {planType === "customer_behavior" || planType === "repeat" ? (
            <div className="customer-behavior-trigger-card">
              <Row className="trigger-date-row" style={{ marginTop: "30px" }}>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label behavior-trigger-label"
                >
                  执行时间：
                </Label>
                <Col>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="executionTime"
                      id="formRadios1"
                      checked={isLongEffective === "forever"}
                      value="forever"
                      disabled={isDisabled}
                      onChange={(e) => {
                        onHandleIsForeverChange(e);
                        setIsLongEffective(e.target.value);
                      }}
                    />
                    <label className="form-check-label" htmlFor="formRadios1">
                      长期有效
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="executionTime"
                      id="formRadios2"
                      value="delay"
                      disabled={isDisabled}
                      checked={isLongEffective === "delay"}
                      onChange={(e) => {
                        onHandleIsForeverChange(e);
                        setIsLongEffective(e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="formRadios2"
                    ></label>
                    <div className="div-trigger-date">
                      <ConfigProvider locale={locale}>
                        <RangePicker
                          className="foreverDateTime"
                          disabled={isLongEffective === "forever" || isDisabled}
                          onChange={handelForeverDate}
                          value={[
                            dayjs(foreverDateArr[0]),
                            dayjs(foreverDateArr[1]),
                          ]}
                          // disabledDate={disabledDate}
                        />
                      </ConfigProvider>
                      <span className="span2">有效</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label behavior-trigger-label"
                >
                  参与限制：
                </Label>
                <Col>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="limit"
                      id="1"
                      value="once"
                      disabled={isDisabled}
                      checked={marketing.limit_type === "once"}
                      onChange={onHandleLimitChange}
                    />
                    <label className="form-check-label" htmlFor="1">
                      仅限1次
                    </label>
                    <TooltipCom
                      type="7"
                      tooltipTopId="tooltipTop7"
                    ></TooltipCom>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="limit"
                      id="2"
                      value="unlimited"
                      disabled={isDisabled}
                      checked={marketing.limit_type === "unlimited"}
                      onChange={onHandleLimitChange}
                    />
                    <label className="form-check-label" htmlFor="2">
                      不限制次数
                    </label>
                    <TooltipCom
                      type="8"
                      tooltipTopId="tooltipTop8"
                    ></TooltipCom>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="limit"
                      id="3"
                      value="custom"
                      disabled={isDisabled}
                      checked={marketing.limit_type === "custom"}
                      onChange={(e) => {
                        onHandleLimitChange(e);
                        setLimitType("custom");
                      }}
                    />
                    <label className="form-check-label" htmlFor="3"></label>
                    <div className="div-limit">
                      <span className="span1">同一用户</span>
                      <Input
                        className="form-control"
                        type="number"
                        min={1}
                        id="example-number-input"
                        value={limitCount}
                        disabled={limitType !== "custom" || isDisabled}
                        onChange={(e) => {
                          setLimitCount(e.target.value);
                          setMarketing({
                            ["limit_count_period"]: e.target.value,
                          });
                        }}
                      />
                      <select
                        className="form-select"
                        value={limitUnit}
                        disabled={limitType !== "custom" || isDisabled}
                        onChange={(e) => {
                          setLimitUnit(e.target.value);
                          setMarketing({
                            ["limit_count_period_unit"]: e.target.value,
                          });
                        }}
                      >
                        {units?.map((v, i) => {
                          return (
                            <option value={v.value} key={i}>
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                      <span className="span2"> 内，最多参与一次</span>
                      <TooltipCom
                        type="9"
                        tooltipTopId="tooltipTop9"
                      ></TooltipCom>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default PlanTypeCom;
